export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const DASHBOARD_FETCHED = "DASHBOARD_FETCHED";
export const REPORT_REQUESTS_FETCHED = "REPORT_REQUESTS_FETCHED";
export const PARTNER_REQUESTS_FETCHED = "PARTNER_REQUESTS_FETCHED";
export const LEAKEDCREDS_REQUESTS_FETCHED = "LEAKEDCREDS_REQUESTS_FETCHED";
export const LEAKEDCREDS_REQUESTS_FETCHED_TOTAL = "LEAKEDCREDS_REQUESTS_FETCHED_TOTAL";
export const LEAKEDCREDS_REQUESTS_FETCHED_GRAPH = "LEAKEDCREDS_REQUESTS_FETCHED_GRAPH";
export const TELEGRAM_REQUESTS_FETCHED = "TELEGRAM_REQUESTS_FETCHED";
export const TELEGRAM_REQUESTS_FETCHED_TOTAL = "TELEGRAM_REQUESTS_FETCHED_TOTAL";
// export const TELEGRAM_REQUESTS_FETCHED_PHONE = "TELEGRAM_REQUESTS_FETCHED_PHONE";
export const LEAKEDCODE_REQUESTS_FETCHED = "LEAKEDCODE_REQUESTS_FETCHED";

export const LOOK_ALIKE_FETCHED = "LOOK_ALIKE_FETCHED";
export const LOOK_ALIKE_FETCHED_BY_ID = "LOOK_ALIKE_FETCHED_BY_ID";
export const CUSTOMERS_FETCHED = "CUSTOMERS_FETCHED";
export const VENDORS_FETCHED = "VENDORS_FETCHED";

export const CSPM_FETCHED = "CSPM_FETCHED";

export const DOMAIN_SQUATING = "DOMAIN_SQUATING";
export const DOMAIN_SQUATING_DOMAIN = "DOMAIN_SQUATING_DOMAIN";
export const DOMAIN_SQUATING_TOTAL = "DOMAIN_SQUATING_TOTAL";
export const DOMAIN_SQUATING_GRAPH = "DOMAIN_SQUATING_GRAPH";


export const CUSTOMER_CREATED = "CUSTOMER_CREATED";
export const VENDOR_CREATED = "VENDOR_CREATED";
export const CSPM_CREATED = "CSPM_CREATED";

export const CODE_CREATED = "CODE_CREATED";

export const CUSTOMERS_UPDATED = "CUSTOMERS_UPDATED";
export const CREATING_CUSTOMER = "CREATING_CUSTOMER";
export const CREATING_VENDOR = "CREATING_VENDOR";

export const CREATING_FOREST_VIEW = "CREATING_FOREST_VIEW";
export const UPDATE_FOREST_VIEW = "UPDATE_FOREST_VIEW";
export const DELETE_FOREST_VIEW = "DELETE_FOREST_VIEW";
export const GET_FOREST_VIEW = "GET_FOREST_VIEW";
export const FOREST_GRAPH_ATTACKSURFACE = "FOREST_GRAPH_ATTACKSURFACE";
export const FOREST_GRAPH_DATAEXPOSURE = "FOREST_GRAPH_DATAEXPOSURE";
export const FOREST_GRAPH_BRANDSECURITY = "FOREST_GRAPH_BRANDSECURITY";



export const CREATING_CSPM = "CREATING_CSPM";

export const CREATING_CODE_RECORD = "CREATING_CODE_RECORD";

export const INVENTORY_FETCHED = "INVENTORY_FETCHED";
export const INVENTORY_FETCHED_TOTALS = "INVENTORY_FETCHED_TOTALS";
export const INVENTORY_FETCHED_GRAPH = "INVENTORY_FETCHED_GRAPH";
export const SINGLE_INVENTORY_FETCHED = "SINGLE_INVENTORY_FETCHED";
export const FETCHING_SINGLE_INVENTORY = "FETCHING_SINGLE_INVENTORY";
export const SINGLE_INVENTORY_DATA_FETCHED = "SINGLE_INVENTORY_DATA_FETCHED";
export const EDIT_CUSTOMER_FETCHED = "EDIT_CUSTOMER_FETCHED";
export const UPDATING_CUSTOMER = "UPDATING_CUSTOMER";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const DELETING_CUSTOMER = "DELETING_CUSTOMER";
export const INVENTORY_FETCHED_DISCOVERY = "INVENTORY_FETCHED_DISCOVERY";
export const ALL_PORT = "ALL_PORT";
export const ALL_COUNTRY = "ALL_COUNTRY";
export const ALL_CLOUD = "ALL_CLOUD";
export const INVENTORY_FETCHED_DISCOVERY_TOTAL = "INVENTORY_FETCHED_DISCOVERY_TOTAL";
export const INVENTORY_FETCHED_DISCOVERY_GRAPH = "INVENTORY_FETCHED_DISCOVERY_GRAPH";




export const CUSTOMER_DELETED = "CUSTOMER_DELETED";

export const FETCHING_CUSTOMERS = "FETCHING_CUSTOMERS";
export const FETCHING_VENDORS = "FETCHING_VENDORS";

export const FETCHING_CSPM = "FETCHING_CSPM";

export const FETCHING_THREAT_ANALYIST = "FETCHING_THREAT_ANALYIST";
export const FETCHING_CODE = "FETCHING_CODE";

export const GETING_DATA_BY_LOCALSTORAGE = "GETING_DATA_BY_LOCALSTORAGE";
export const USERS_FETCHED = "USERS_FETCHED";
export const USERS_CREATED = "USERS_CREATED";
export const CREATING_USER = "CREATING_USER";
export const EDIT_USER_FETCHED = "EDIT_USER_FETCHED";
export const LOGED_IN_USER = "LOGED_IN_USER";
export const UPDATING_USER = "UPDATING_USER";
export const USER_UPDATED = "USER_UPDATED";
export const DELETING_USER = "DELETING_USER";
export const USER_DELETED = "USER_DELETED";
export const FETCHING_USERS = "FETCHING_USERS";
export const FETCH_EXEL_DATA = "FETCH_EXEL_DATA";


// summary
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const FETCH_CLIENTS_UPDATE = "FETCH_CLIENTS_UPDATE";
export const SUMMARY_FETCHED = "SUMMARY_FETCHED";

// fake-mobile-app
export const FETCH_MOBILE_APP = "FETCH_MOBILE_APP";
export const FETCH_MOBILE_APP_TOTAL = "FETCH_MOBILE_APP_TOTAL";
export const FETCH_MOBILE_APP_EXCEL = "FETCH_MOBILE_APP_EXCEL";
// export const FETCH_MOBILE_APP = "FETCH_MOBILE_APP";

export const PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED";
export const PASSWORD_RESET_DONE = "PASSWORD_RESET_DONE";

// sub domain
export const SUBDOMAIN_FETCHED = "SUBDOMAIN_FETCHED";
export const SUBDOMAIN_FETCHED_SSL = "SUBDOMAIN_FETCHED_SSL";
export const SUBDOMAIN_FETCHED_SSL_TOTAL = "SUBDOMAIN_FETCHED_SSL_TOTAL";
export const SUBDOMAIN_FETCHED_SSL_GRAPH = "SUBDOMAIN_FETCHED_SSL_GRAPH";
export const SUBDOMAIN_FETCHED_TOTAL = "SUBDOMAIN_FETCHED_TOTAL";
export const SUBDOMAIN_FETCHED_GRAPH = "SUBDOMAIN_FETCHED_GRAPH";

export const FETCH_SUBDOMAIN_DETAIL = "FETCH_SUBDOMAIN_DETAIL";
export const SUBDOMAIN_SCAN_STARTED = "SUBDOMAIN_SCAN_STARTED";
export const SCAN_LIST = "SCAN_LIST";
export const  FETCH_VULNERABLE_SSL = "FETCH_VULNERABLE_SSL";
export const  FETCH_VULNERABLE_SSL_TOTAL = "FETCH_VULNERABLE_SSL_TOTAL";
export const  FETCH_VULNERABLE_SSL_GRAPH = "FETCH_VULNERABLE_SSL_GRAPH";


// inventoryDetails
export const INVENTORY_DETAILS_FETCHED = "INVENTORY_DETAILS_FETCHED";
export const INVENTORY_DETAILS_FETCHEDV1 = "INVENTORY_DETAILS_FETCHEDV1";
export const ALL_INVENTORY_FETCHED = "ALL_INVENTORY_FETCHED"

// Blank Page
export const SOURCE_CODE_FETCHED = "SOURCE_CODE_FETCHED";

//Social Media
export const SOCIAL_MEDIA_PAGE_FETCHED = "SOCIAL_MEDIA_PAGE_FETCHED";


// cloud

export const SUSPICIOUS_CLOUD_TOTAL = "SUSPICIOUS_CLOUD_TOTAL";
export const SUSPICIOUS_CLOUD_GRAPH = "SUSPICIOUS_CLOUD_GRAPH";


export const API_SCAN_FETCHED = "API_SCAN_FETCHED";
export const API_SCAN_CREATED = "API_SCAN_CREATED";
export const API_SCAN_DETAILS_FETCHED = "API_SCAN_DETAILS_FETCHED"

export const SCHEDULER_FETCHED = "SCHEDULER_FETCHED";
export const SCHEDULER_SUBDOMAIN_FETCHED = "SCHEDULER_SUBDOMAIN_FETCHED";

export const FETCHING_ORGANISATION = "FETCHING_ORGANISATION";

export const FETCH_DARK_WEB_MENTION = "FETCH_DARK_WEB_MENTION";
export const FETCH_FAKE_SOCIAL_MEDIA = "FETCH_FAKE_SOCIAL_MEDIA";
export const FETCH_FAKE_SOCIAL_MEDIA_TOTAL = "FETCH_FAKE_SOCIAL_MEDIA_TOTAL";
export const FETCH_FAKE_SOCIAL_MEDIA_EXCEL = "FETCH_FAKE_SOCIAL_MEDIA_EXCEL";


export const REPORT_DOWNLOAD = "REPORT_DOWNLOAD";

export const MAIN_SCREEN_SUMMARY_FETCHED = "MAIN_SCREEN_SUMMARY_FETCHED";

export const FETCH_CVE_DETAILS = "FETCH_CVE_DETAILS";
export const FETCH_CVE_DETAILS_GRAPH = "FETCH_CVE_DETAILS_GRAPH";
export const FETCH_CVE_DETAILS_TOTAL = "FETCH_CVE_DETAILS_TOTAL";

export const FETCH_AV = "FETCH_AV";
export const FETCH_AV_SCORE = "FETCH_AV_SCORE";
export const FETCH_AV_SUMMARY = "FETCH_AV_SUMMARY";
export const FETCH_AV_STATS = "FETCH_AV_STATS";
export const FETCH_AV_DETAIL = "FETCH_AV_DETAIL";

export const FETCH_DNS_DETAILS = "FETCH_DNS_DETAILS";
export const FETCH_SUCCESS_DNS_DETAILS = "FETCH_SUCCESS_DNS_DETAILS";
export const FETCH_SUCCESS_DNS_DETAILS_TOTAL = "FETCH_SUCCESS_DNS_DETAILS_TOTAL";
export const FETCH_SUCCESS_DNS_DETAILS_GRAPH = "FETCH_SUCCESS_DNS_DETAILS_GRAPH";



export const DASHBOARD_SUMMARY_FETCHED = "DASHBOARD_SUMMARY_FETCHED";
export const VENDOR_SUMMARY_FETCHED = "VENDOR_SUMMARY_FETCHED";

export const CREATE_CLOUD_ACCOUNT = "CREATE_CLOUD_ACCOUNT";
export const GET_SCAN_BY_ACCOUNT_ID = "GET_SCAN_BY_ACCOUNT_ID";
export const GET_RESULT_BY_ACCOUNT_ID = "GET_RESULT_BY_ACCOUNT_ID";
export const GET_SCAN_DETAILS = "GET_SCAN_DETAILS";

export const CREATE_INTEGRATION = "CREATE_INTEGRATION";
export const FETCH_INTEGRATION = "FETCH_INTEGRATION";
export const ALERTS_FETCHED = "ALERTS_FETCHED";
export const FETCHING_ALERTS = "FETCHING_ALERTS";

export const MALWARE_CREDENTIALS_FETCHED = "MALWARE_CREDENTIALS_FETCHED";
export const MALWARE_CREDENTIALS_FETCHED_TOTAL = "MALWARE_CREDENTIALS_FETCHED_TOTAL";
export const MALWARE_CREDENTIALS_FETCHED_GRAPH = "MALWARE_CREDENTIALS_FETCHED_GRAPH";
export const LEAKED_SESSION_FETCHED = "LEAKED_SESSION_FETCHED";
export const LEAKED_SESSION_FETCHED_GRAPH = "LEAKED_SESSION_FETCHED_GRAPH";
export const LEAKED_SESSION_FETCHED_TOTAL = "LEAKED_SESSION_FETCHED_TOTAL";


export const PASTEBIN_RECORD_FETCHED = "PASTEBIN_RECORD_FETCHED";
export const PASTEBIN_RECORD_FETCHED_GRAPH = "PASTEBIN_RECORD_FETCHED_GRAPH";
export const PASTEBIN_RECORD_FETCHED_TOTAL= "PASTEBIN_RECORD_FETCHED_TOTAL";
export const GET_PHISHING_PAGE = "GET_PHISHING_PAGE";
export const GET_POST_MAN = "GET_POST_MAN";
export const GET_POST_MAN_GRAPH = "GET_POST_MAN_GRAPH";
export const GET_POST_MAN_TOTAL = "GET_POST_MAN_TOTAL";
export const GET_POST_MAN_DETAILS = "GET_POST_MAN_DETAILS";


export const FETCH_WEB_DEFACEMENT = "FETCH_WEB_DEFACEMENT";
export const TWO_FACTOR_ENABLED = "TWO_FACTOR_ENABLED";

export const SECURITY_HEADER = "SECURITY_HEADER";

export const UPDATE_COUNTER = "UPDATE_COUNTER";
export const PII_DETAILS = "PII_DETAILS";
export const PII_DETAILS_TOTAL = "PII_DETAILS_TOTAL";
export const PII_DETAILS_GRAPH = "PII_DETAILS_GRAPH";


export const GET_TRIAL_REQUEST = "GET_TRIAL_REQUEST";

export const GET_PLAN = "GET_PLAN";

export const CREATE_PLAN = "CREATE_PLAN";
export const CREATE_ENTERPRISE = "CREATE_ENTERPRISE";


// CVE
export const SEARCH_CVE = "CREATE_PLAN";
export const CVE_DATA = "CVE_DATA";
export const SEARCH_PRDOUCT_VENDOR = "SEARCH_PRDOUCT_VENDOR";
export const SEARCH_PRDOUCT_PRODUCT = "SEARCH_PRDOUCT_PRODUCT";
export const VENDOR_PRODUCT = "VENDOR_PRODUCT";
export const CVE_SEARCH_MONITORING = "CVE_SEARCH_MONITORING";
export const CVE_MONITORING_TECH = "CVE_MONITORING_TECH";
export const CVE_MONITORING_TECH_SUBS = "CVE_MONITORING_TECH_SUBS";



// dark web

export const DARK_WEB = "DARK_WEB";


