import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import {
  getSuspicousCloudData,
  getSuspicousCloudAction,
  getSuspicousCloudTotal,
  getSuspicousCloudGraph,
} from "../../actions/fakeMobileApp";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import Pagination from "../Pagination/Pagination";
import BasicLineColumn from "../common/apexChart/lineChart";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SourceCodeLeakage = () => {
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const myRef = useRef(null);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [filterData, setfilterData] = useState("");
  const [category, setCategory] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const dispatch = useDispatch();

  const state = useSelector(
    (state) => state?.openCloud?.suspicious?.data?.data
  );
  const totalResponse = useSelector(
    (state) => state?.openCloud?.cloudbuckettotal?.data
  );
  const totalCount = useSelector(
    (state) => state?.openCloud?.suspicious?.data?.totalProfiles
  );
  let oldDnsDetails = useSelector(
    (state) => state.openCloud?.cloudbucketgraph?.data
  );

  let ipArray = [];
  for (
    let details = 0;
    details < oldDnsDetails?.scanDetails?.length;
    details++
  ) {
    ipArray.push(oldDnsDetails?.scanDetails[details]);
  }

  useEffect(() => {
    getSuspicousCloudData(id, dispatch, page, category, filterData);
  }, [id, dispatch, page, category, filterData]);

  useEffect(() => {
    setTotalItems(totalCount);
  }, [totalCount]);

  useEffect(() => {
    getSuspicousCloudTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    getSuspicousCloudGraph(id, dispatch);
  }, [id, dispatch]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const auth = useSelector((state) => state?.auth?.user);

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        cloudBucketDetailIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      getSuspicousCloudAction(id, dispatch, page, category, filterData, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      cloudBucketDetailId: selectedIds == 0 ? ids : selectedIds,
    };
    getSuspicousCloudAction(id, dispatch, page, category, filterData, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      cloudBucketDetailIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    getSuspicousCloudAction(id, dispatch, page, category, filterData, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const executeScroll = (val) => {
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/cloud-bucket-details/${id}/graph`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Open Cloud Buckets.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  let currentTableData = [];

  let mapData = state;

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Cloud Storage</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{   }}
                  >
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalResponse?.individualRating}</b>
                </span>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {client?.subscriptionType != "trial" && mapData?.length !== 0 && (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    href="/add-report"
                    style={{   fontWeight: "bold" }}
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o mr-1"
                      style={{   fontWeight: "bold" }}
                    ></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
            {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
                            <span className="btn btn-sm btn-primary mr-1" title="">Create Campaign</span>
                            <a href="https://themeforest.net/item/BrandSek-bootstrap-4x-admin-dashboard-clean-modern-ui-kit/23091507" className="btn btn-sm btn-success" title="Themeforest"><i className="icon-basket"></i> Buy Now</a>
                        </div> */}
          </div>
        </div>
        <div className="row clearfix">
          <div
            className=" col-md-12 col-sm-12"
            style={{  }}
          >
            <div className="row clearfix">
              <div className="col-md-3 col-sm-12">
                <div className="card w_card3 card-box">

                <div className="d-flex justify-content-between">
                <i className="fa fa-amazon icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {totalResponse?.totalAWSCloudBuckets || 0}
                </h4>
              </div>
              <label className="mb-0 text-right"> </label>

              <div className="card-footer">
                 
                <a onClick={() => executeScroll("aws")}
                        style={{  fontWeight: "bold" }}
                        className="btn btn-border-footer"
                      ><i className="fa fa-arrow-right" style={{}}></i>
                        Find more
                      </a>
                 
              </div>


                  
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card w_card3 card-box">
                <div className="d-flex justify-content-between">
                <i className="fa fa-windows icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {totalResponse?.totalDOSCloudBuckets || 0}
                </h4>
              </div>
              <label className="mb-0 text-right"> </label>

              <div className="card-footer">
                 
                <a onClick={() => executeScroll("dos")}
                        style={{  fontWeight: "bold" }}
                        className="btn btn-border-footer"
                      ><i className="fa fa-arrow-right" style={{}}></i>
                        Find more
                      </a>
                 
              </div>
 
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card w_card3 card-box">
                <div className="d-flex justify-content-between">
                <i className="fa fa-google icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {totalResponse?.totalGCPCloudBuckets || 0}
                </h4>
              </div>
              <label className="mb-0 text-right"> </label>

              <div className="card-footer">
                 
                <a  onClick={() => executeScroll("gcp")}
                        style={{  fontWeight: "bold" }}
                        className="btn btn-border-footer"
                      ><i className="fa fa-arrow-right" style={{}}></i>
                        Find more
                      </a>
                 
              </div>

                  
                </div>
              </div>
              <div className="col-md-3 col-sm-12">
                <div className="card w_card3 card-box mr-0">
                <div className="d-flex justify-content-between">
                <i className="fa fa-cloud icon-dashboard" style={{}}></i>

                <h4 className="font-44 total-h4">
                {totalResponse?.totalAZURECloudBuckets || 0}
                </h4>
              </div>
              <label className="mb-0 text-right"> </label>

              <div className="card-footer">
                 
                <a  onClick={() => executeScroll("cloud")}
                        style={{  fontWeight: "bold" }}
                        className="btn btn-border-footer"
                      ><i className="fa fa-arrow-right" style={{}}></i>
                        Find more
                      </a>
                 
              </div>
 
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                {client?.subscriptionType == "trial" && (
                  <div className="card border-warning">
                    <TrialComponent />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className=" col-md-12 col-sm-12">
            <div className="card white-box-dashboard">
              <div className="body" style={{ minHeight: "410px" }}>
                <h6>Previous scan details</h6>
                {oldDnsDetails && (
                  <BasicLineColumn
                    dates={oldDnsDetails?.dates}
                    ipArray={ipArray}
                    color={["#DD3731"]}
                    yAxis="Records Found"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-table-tab nav-link ${
                  category === "all" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("all");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                Current
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "risk-accepted" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("risk-accepted");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                Risk Accepted
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "false-positive" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("false-positive");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                False Positive
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "remidiated" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("remidiated");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                Remediated
              </a>
            </li>
          </ul>
          <div className="body">
            <div className="row" ref={myRef}>
              <div className="col-sm-4">
                {/* <Autocomplete
                  multiple
                  id="tags-standard"
                  onChange={(event, newValue) => {
                    setfilterData(newValue);
                  }}
                  value={filterData}
                  options={['aws', 'azure', 'gcp', 'dos']}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Cloud Storage"
                      color="white"
                    />
                  )}
                /> */}
                <select
                  className="custom-select custom-select-sm form-control form-control-sm"
                  onChange={(e) => setfilterData(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="aws">AWS</option>
                  <option value="azure">Azure</option>
                  <option value="gcp">Google</option>
                  <option value="dos">Dos</option>
                </select>
              </div>
              <div className="col-sm-4">
                {selectedIds.length > 1 && category === "all" && (
                  <Box sx={{ width: 200 }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedValue}
                        onChange={(event) => {
                          setAction(event.target.value);
                          snooze("", event.target.value);
                        }}
                      >
                        <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                        <MenuItem
                          value="riskAccepted"
                          onClick={() => {
                            addModal();
                          }}
                        >
                          Risk Accepted
                        </MenuItem>
                        <MenuItem
                          value="markAsFalsePositive"
                          onClick={() => {
                            addModal();
                          }}
                        >
                          False Positive
                        </MenuItem>
                        <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {selectedIds.length > 1 &&
                  (category == "false-positive" ||
                    category == "risk-accepted") && (
                    <button
                      className="btn btn-primary btn-sm mr-1 Unsnooze"
                      onClick={() => Unsnoozed("")}
                    >
                      Unsnooze
                    </button>
                  )}
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                        <th>#</th>
                        <th>Type</th>
                        <th>Bucket Name</th>
                        <th>File Name</th>
                        <th>File URL</th>
                        <th>File Size (KBs)</th>
                        <th>Last Updated on </th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={7}>
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map((res, index) => {
                          let fullPath = res?.fullPath?.split("") || ["N", "A"];
                          let url = res?.url?.split("") || ["N", "A"];
                          let bucket = res?.bucket?.split("") || ["N", "A"];

                          return (
                            <tr key={index}>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(res?._id)}
                                    checked={selectedIds?.includes(res?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                              <td style={{ whiteSpace: "unset" }}>
                                <span>{index + 1}</span>
                              </td>

                              <td>{res.type}</td>
                              <td>
                                <a
                                  href={`${res?.bucket}`}
                                  target="_blank"
                                  style={{  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {bucket?.map((record, indexs) => {
                                      if ((indexs + 1) % 40 == 0)
                                        return (
                                          <>
                                            <span>{record}</span>
                                            <br />
                                          </>
                                        );
                                      else
                                        return (
                                          <>
                                            <span>{record}</span>
                                          </>
                                        );
                                    })}
                                  </span>
                                  {/* {res?.url} */}
                                </a>
                              </td>

                              <td>
                                <span
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {fullPath?.map((record, indexs) => {
                                    if ((indexs + 1) % 40 == 0)
                                      return (
                                        <>
                                          <span>{record}</span>
                                          <br />
                                        </>
                                      );
                                    else
                                      return (
                                        <>
                                          <span>{record}</span>
                                        </>
                                      );
                                  })}
                                </span>
                              </td>
                              <td>
                                <a
                                  href={`${res?.url}`}
                                  target="_blank"
                                  style={{  }}
                                >
                                  <span
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {url?.map((record, indexs) => {
                                      if ((indexs + 1) % 40 == 0)
                                        return (
                                          <>
                                            <span>{record}</span>
                                            <br />
                                          </>
                                        );
                                      else
                                        return (
                                          <>
                                            <span>{record}</span>
                                          </>
                                        );
                                    })}
                                  </span>
                                  {/* {res?.url} */}
                                </a>
                              </td>

                              <td>{res.size}</td>
                              <td>
                                {" "}
                                {res?.lastModified
                                  ? moment(res?.lastModified * 1000).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                  : res?.createdAt
                                  ? moment(res?.createdAt).format(
                                      "DD-MM-YYYY HH:MM"
                                    )
                                  : "NA"}
                              </td>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(res?._id, event.target.value);
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([res._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([res._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                              {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(res?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className=" ">
                  <div className="card mb-0">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceCodeLeakage;
