import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Link  , useParams} from "react-router-dom";
import {
  getSubDomainDetails,
  getSubDomainDetailsTotal,
  getSubDomainDetailsGraph,
  getSubDomainDetailsAction,
} from "../../actions/sub-domain";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/lineChart";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Link, useParams } from "react-router-dom";

var Model = require("../information/model");

const Domain = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const myRef = useRef(null);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);

  const [filterData, setfilterData] = useState("all");
  const [domainFilter, setDomainFilter] = useState("all");
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const queryParameters = new URLSearchParams(window.location.search);
  const latest = queryParameters.get("latest");

  const auth = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const statusParam = params.get("status");
    setfilterData(statusParam);
  }, []);

  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let domain = client?.website?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  if (domain?.charAt(domain.length - 1) == "/") {
    domain = [domain.substr(0, domain.length - 1)];
    // domain.unshift("all");
  } else {
    domain = [domain];
    // domain.unshift("all");
  }
  if (client?.domains && client.domains.length > 0) {
    domain = client.domains;
    // domain.unshift("all");
  }
  let dnsDetailsArray = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails?.data?.data
  );
  let dnsDetailsTotal = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails?.data
  );
  let activeInactive = useSelector(
    (state) => state?.subDomain?.subdomainDnsDetails
  );
  let oldDnsDetails = useSelector(
    (state) => state?.subDomain?.subDomainDetailGraph?.data
  );
  let totalCount = useSelector(
    (state) => state?.subDomain?.subDomainDetailTotal?.data
  );

  let scanCompleted = useSelector((state) => state?.subDomain?.scanCompleted);
  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      getSubDomainDetailsAction(
        id,
        dispatch,
        page,
        category,
        filterData,
        search,
        domainFilter,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      subDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    getSubDomainDetailsAction(
      id,
      dispatch,
      page,
      category,
      filterData,
      search,
      domainFilter,
      data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    getSubDomainDetailsAction(
      id,
      dispatch,
      page,
      category,
      filterData,
      search,
      domainFilter,
      data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  useEffect(() => {
    if (orgId) {
      getSubDomainDetails(
        orgId,
        dispatch,
        page,
        category,
        filterData,
        search,
        domainFilter
      );
    } else {
      getSubDomainDetails(
        id,
        dispatch,
        page,
        category,
        filterData,
        search,
        domainFilter
      );
    }
  }, [id, dispatch, page, category, filterData, search, domainFilter]);

  useEffect(() => {
    setTotalItems(dnsDetailsTotal?.totalDomains);
  }, [dnsDetailsTotal?.totalDomains]);

  useEffect(() => {
    getSubDomainDetailsGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    getSubDomainDetailsTotal(id, dispatch);
  }, [id, dispatch]);

  //  let distinctFilter = ["All",'Active','InActive',"Latest"];
  let distinctFilter = ["all", "active", "inactive", "dangling"];

  let openPortArray = [];
  let ipArray = [];
  if (oldDnsDetails?.scanDetails) {
    for (let ip = 0; ip < oldDnsDetails?.scanDetails?.length; ip++) {
      ipArray.push(oldDnsDetails?.scanDetails?.[ip] || 0);
    }
  }

  const handleChange = (e) => {
    const fileReader = new FileReader();
    let fileType = e.target.files[0].type;
    // if (fileType != "application/json")
    //   toastr.error("Error", "Please upload json file.");

    Model.default.uploadSubdomain(e.target.files[0], id).then((response) => {
      //console.log({ response });
      if (!response.error) {
        //console.log(response.data, "data");
        toastr.success("Success", "Subdomain imported.");
        let orgLogo = response.data;
        getSubDomainDetails(id, dispatch);
      }
    });
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `https://api.brandsek.com/api/getSubDomainDetails/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Sub-Domain Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  let mapData = dnsDetailsArray;

  mapData = mapData;

  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  console.log(totalItems, "totalItems");
  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Subdomain Enumeration</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalCount?.individualRating}</b>
                </span>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 hidden-xs">
              {mapData?.length === 0 ? null : (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    href="/add-report"
                    style={{
                      float: "right",
                       
                    }}
                    className="btn btn-primary ml-1 mb-2"
                  >
                    <i className="fa fa-file-excel-o mr-1" style={{}}></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
              {auth.accessLevel === "Admin" && (
                <div
                  className="custom-file"
                  style={{
                    width: "300px",
                    float: "right",
                    marginRight: "0",
                  }}
                >
                  {/* <span>Upload IP</span> */}
                  <input
                    onChange={handleChange}
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile01"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="inputGroupFile01"
                  >
                    Upload Subdomain
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-4 col-md-12">
            <div className="card w_card3 card-box">
              <div className="d-flex justify-content-between">
                <i className="font-50 fa fa-database icon-dashboard"></i>
                <h4 className="font-44 total-h4">{totalCount?.totalDomains}</h4>
              </div>
              <label className="mb-0 text-right">Records Found</label>
              <hr className="border-red"></hr>
              <div className="body pt-1" style={{}}>
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => setfilterData("active")}
                  >
                    Active Subdomains{" "}
                    <span className="float-right">
                      {totalCount?.totalActiveDomains}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-warning"
                      role="progressbar"
                      // aria-valuenow={}
                      aria-valuemin="10"
                      aria-valuemax="100"
                      style={{}}
                    ></div>
                  </div>
                </div>
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => setfilterData("inactive")}
                  >
                    Inactive Subdomains{" "}
                    <span className="float-right">
                      {totalCount?.totalInactivedomains}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-warning"
                      role="progressbar"
                      // aria-valuenow={}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{}}
                    ></div>
                  </div>
                </div>

                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => setfilterData("dangling")}
                  >
                    Dangling Subdomains{" "}
                    <span className="float-right">
                      {/* {dangling?.length?.toFixed(0)} */}
                      {totalCount?.totalDanglingDomains}
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-warning"
                      role="progressbar"
                      // aria-valuenow={}
                      aria-valuemin="10"
                      aria-valuemax="100"
                      style={{}}
                    ></div>
                  </div>
                </div>

                {/* {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )} */}
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="white-box-dashboard">
              <div className="body" style={{ minHeight: "410px" }}>
                <h4>Overall Security Health Score</h4>
                {oldDnsDetails && (
                  <BasicLineColumn
                    dates={oldDnsDetails?.dates}
                    ipArray={oldDnsDetails?.scanDetails}
                    color={["#DD3731"]}
                    yAxis="Records Found"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-12 col-sm-12">
            <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-table-tab nav-link ${
                    category === "all" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("all");
                    setPage(1);
                    setfilterData("all");
                    setDomainFilter("all");
                    setSearch("");
                    setSelectedIds([]);
                  }}
                >
                  Current
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "risk-accepted" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("risk-accepted");
                    setPage(1);
                    setfilterData("all");
                    setDomainFilter("all");
                    setSearch("");
                    setSelectedIds([]);
                  }}
                >
                  Risk Accepted
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "false-positive" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("false-positive");
                    setPage(1);
                    setfilterData("all");
                    setDomainFilter("all");
                    setSearch("");
                    setSelectedIds([]);
                  }}
                >
                  False Positive
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "remidiated" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("remidiated");
                    setPage(1);
                    setfilterData("all");
                    setDomainFilter("all");
                    setSearch("");
                    setSelectedIds([]);
                  }}
                >
                  Remediated
                </a>
              </li>
            </ul>
            <div className="card">
              <div className="body">
                <div className="row" ref={myRef}>
                  <div className="col-sm-3">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      {distinctFilter?.map((row) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={domainFilter}
                      onChange={(e) => setDomainFilter(e.target.value)}
                    >
                      <option value="all">All</option>
                      {domain?.map((row, index) => {
                        return (
                          <option value={row}>{row?.toUpperCase()}</option>
                        );
                      })}
                    </select>
                    {/* domain */}
                  </div>
                  <div className="col-sm-3">
                    <div
                      data-test="datatable-input"
                      class="mdb-datatable-filter flex-row"
                    >
                      <input
                        class="form-control form-control-sm ml-0 my-1"
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>
              </div>

              <div className="table-responsive invoice_list mb-4">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                        <th>
                          <input
                            type="checkbox"
                            checked={selectedIds == 0 ? false : multiCheckTrue}
                            onClick={() => {
                              multicheck(currentTableData);
                            }}
                            className="checkUncheck"
                          />
                        </th>
                      )}
                      <th>#No</th>
                      {/* <th>Status</th> */}
                      <th>Status</th>
                      <th>Domain Name</th>
                      {/* <th>Record Type</th> */}
                      <th>IP Address</th>
                      <th>Service</th>
                      <th style={{ textAlign: "center" }}>Last modified on</th>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                        <th style={{ width: "80" }}>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      // //console.log(row?.techStack?.urls?.[
                      //   `https://${row?.subDomainName}/`
                      // ]?.status, "rowaa",row?._doc);
                      return (
                        <tr>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <input
                                onClick={() => singleClick(row?._id)}
                                checked={selectedIds?.includes(row?._id)}
                                type="checkbox"
                                className="checkUncheck"
                              />
                            </td>
                          )}
                          <td>{index + 1}</td>
                          <td style={{ whiteSpace: "unset" }}>
                            <span
                              className={
                                row?.isActive == true
                                  ? "badge badge-success"
                                  : row?.isDangling == true
                                  ? "badge badge-warning"
                                  : "badge badge-danger"
                              }
                            >
                              {row?.isActive == true
                                ? "Active"
                                : row?.isDangling == true
                                ? "Dangling"
                                : "In Active"}
                            </span>
                          </td>
                          {/* <td>
                            {row?.techStack?.urls?.[
                                  `https://${row?.subDomainName}/`
                                ]?.status || "NA"}
                          </td> */}

                          <td style={{ whiteSpace: "unset" }}>
                            <a
                              href={`https://${row?.subDomainName}`}
                              target="_blank"
                              style={{  }}
                            >
                              {`${row?.subDomainName}`}
                            </a>
                          </td>
                          {/* <td>{row?.recordType}</td> */}
                          <td style={{ whiteSpace: "unset" }}>{row?.ip}</td>
                          <td style={{ whiteSpace: "unset" }}>
                            {row?.server ? row?.server : row?.serverName}
                          </td>
                          <td
                            style={{ whiteSpace: "unset", textAlign: "center" }}
                          >
                            <span className="badge badge-success">
                              {moment(row?.updatedAt).format("DD-MM-YYYY")}
                            </span>
                          </td>
                          {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(row?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed(row?._id)}
                              >
                                Unsnooze
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
              <div className="">
              <div className="card mb-0">
                    <div className="row clearfix">
                       
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                       
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Domain;
