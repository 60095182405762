import axios from "axios";
import { API_URL } from "../utils/constant";
import { FETCH_CVE_DETAILS ,FETCH_CVE_DETAILS_GRAPH , FETCH_CVE_DETAILS_TOTAL,
  FETCH_AV,
  FETCH_AV_SCORE,
  FETCH_AV_STATS,
  FETCH_AV_DETAIL,
  FETCH_AV_SUMMARY, } from "./index";
import { toastr } from "react-redux-toastr";

export const fetchCVEDetails = (props, dispatch, page, pageSize, search , category) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/cve-details/${props}/delta?page=${page}&limit=${pageSize}&severity=${search}&category=${category}`
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchCVEDetailsAction = (props, dispatch, page, pageSize, search , category , data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .post(
        `${API_URL}/cve-details/${props}/action?page=${page}&limit=${pageSize}&severity=${search}&category=${category} `,data
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchCVEDetailsGraph = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/cve-details/${props}/graph`
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS_GRAPH,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS_GRAPH,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchCVEDetailsTotal = (props, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/cve-details/${props}/totals`
      )
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: FETCH_CVE_DETAILS_TOTAL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_CVE_DETAILS_TOTAL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};


export const fetchMasterScan = (props, type, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  const session = localStorage.getItem("session");
  axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

  axios
    .get(`${API_URL}/getMasterScanDetails/${props}?type=${type}`)
    .then((response) => {
      //console.log(response.data);
      dispatch({
        type: "MASTER_SCAN",
        payload: response.data,
      });
    })
    .catch((err) => {
      var message = "Some server error has occurred.";
      if (err.response) {
        message = err.response.data.userMessage;
      }
      dispatch({
        type: "MASTER_SCAN",
        payload: { error: true, userMessage: message },
      });
    });
};

export const getMasterScanData = (props, orgId, dispatch) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props && props != "") {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(`${API_URL}/getMasterScans/${props}/${orgId}`)
      .then((response) => {
        //console.log(response.data);
        dispatch({
          type: "MASTER_SCAN_DETAILS",
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: "MASTER_SCAN_DETAILS",
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchActiveVulnerabilities = (props, dispatch, page, search, category, risk, limit
) => {
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    axios
      .get(
        `${API_URL}/organisations/${props}/active-vulnerabilities/scan/findings?page=${page}&limit=${limit}&serach=${search}&risk=${risk}&category=${category}`
      )
      .then((response) => {
        console.log("API Response:", response.data);
        dispatch({
          type: FETCH_AV,
          payload: response.data,
        });
        console.log("from src/action av");
      })
      .catch((err) => {
        let message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_AV,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchActiveVulnerabilitiesStats = (props, dispatch) => {
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/organisations/${props}/active-vulnerabilities/total-stats`
      )
      .then((response) => {
        dispatch({
          type: FETCH_AV_STATS,
          payload: response.data,
        });
      })
      .catch((err) => {
        let message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_AV_STATS,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchActiveVulnerabilitiesScore = (props, dispatch) => {
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/organisations/${props}/active-vulnerabilities/scan/average-consolidated-severity`
      )
      .then((response) => {
        dispatch({
          type: FETCH_AV_SCORE,
          payload: response.data,
        });
      })
      .catch((err) => {
        let message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_AV_SCORE,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchActiveVulnerabilityDetail = (props, vulId, dispatch) => {
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;

    axios
      .get(
        `${API_URL}/organisations/${props}/active-vulnerabilities/scan/findings/${vulId}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_AV_DETAIL,
          payload: response.data,
        });
      })
      .catch((err) => {
        let message = "Some server error has occurred.";
        if (err.response) {
          message = err.response.data.userMessage;
        }
        dispatch({
          type: FETCH_AV_DETAIL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const fetchActiveVulnerabilityAction = (props, dispatch, data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    // Log details before making the API request
    console.log("Request Details:");
    console.log("URL:", `${API_URL}/organisations/${props}/active-vulnerabilities/findings/action`);
    console.log("Headers:", axios.defaults.headers.common);
    console.log("Request Body:", data);

    axios
      .post(
        `${API_URL}/organisations/${props}/active-vulnerabilities/findings/action`,data
      )
      .then((response) => {
        // console.log("success",response);
        toastr.success("Success:", response.data.message);
        dispatch({
          type: FETCH_AV_DETAIL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        console.log("erorr",err);
        if (err.response) {
          message = err.response.data.userMessage;
          toastr.error("Failed:", err.message);
        }
        dispatch({
          type: FETCH_AV_DETAIL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};

export const setActiveScanStatus = (props, dispatch, data) => {
  // const cookie = new Cookies();
  // const session = cookie.get("session");
  if (props) {
    const session = localStorage.getItem("session");
    axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
    // Log details before making the API request
    // console.log("Request Details:");
    // console.log("URL:", `${API_URL}/organisations/${props}/active-vulnerabilities/scan/${data?.status || "disable"}`);
    // console.log("Headers:", axios.defaults.headers.common);
    // console.log("Request Body:", data);
    axios
      .put(
        `${API_URL}/organisations/${props}/active-vulnerabilities/scan/${data?.status || "disable"}`,data
      )
      .then((response) => {
        // console.log("success",response);
        const { hasActiveVulnerabilityScanningEnabled } = response.data.data;
        const msg = hasActiveVulnerabilityScanningEnabled
          ? "Active vulnerability scanning status is Enabled"
          : "Active vulnerability scanning status is Disabled";
        toastr.success("Success:", msg);
        dispatch({
          type: FETCH_AV_DETAIL,
          payload: response.data,
        });
      })
      .catch((err) => {
        var message = "Some server error has occurred.";
        console.log("erorr",err);
        if (err.response) {
          message = err.response.data.userMessage;
          toastr.error("Failed:", err.message);
        }
        dispatch({
          type: FETCH_AV_DETAIL,
          payload: { error: true, userMessage: message },
        });
      });
  }
};