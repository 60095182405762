import {
    FETCH_CVE_DETAILS ,FETCH_EXEL_DATA,FETCH_CVE_DETAILS_GRAPH , FETCH_CVE_DETAILS_TOTAL,
    FETCH_AV,
    FETCH_AV_SCORE,
    FETCH_AV_SUMMARY,
    FETCH_AV_STATS,
    FETCH_AV_DETAIL,
  } from "../actions";
  
  const initialState = {
    loading: true,
    cveDetails: {},
    masterScan: [],
    masterScanData: [],
    scanCompleted: true,
    cveGraph:[],
    cveTotal:[],
    av: {},
    av_stats: {},
    av_score: {},
    av_detail: {},
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCH_CVE_DETAILS_GRAPH:
        return {
          ...state,
          cveGraph: action.payload,
        };
        case FETCH_CVE_DETAILS_TOTAL:
          return {
            ...state,
            cveTotal: action.payload,
          };
      case FETCH_CVE_DETAILS:
        return {
          ...state,
          cveDetails: action.payload,
          scanCompleted: action.payload.scanCompleted
        };
      case "MASTER_SCAN":
        return {
          ...state,
          masterScan: action.payload
        };
      case "MASTER_SCAN_DETAILS":
        return {
          ...state,
          masterScanData: action.payload
        };
        case FETCH_EXEL_DATA:
          return {
            ...state,
            exelData: action.payload
          };
        case FETCH_AV:
            return {
                ...state,
                loading:false,
                av: action.payload, 
                scanCompleted: action.payload.scanCompleted
            };
        case FETCH_AV_STATS:
            return {
                ...state,
                loading:false,
                av_stats: action.payload, 
                scanCompleted: action.payload.scanCompleted
            };
        case FETCH_AV_SCORE:
            return {
                ...state,
                loading:false,
                av_score: action.payload, 
                scanCompleted: action.payload.scanCompleted
            };
        case FETCH_AV_DETAIL:
            return {
                ...state,
                loading:false,
                av_detail: action.payload, 
                scanCompleted: action.payload.scanCompleted
              };
      default:
        return state;
    }
  }
  