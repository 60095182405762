import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchActiveVulnerabilityDetail } from "../../actions/vulnerability";
// import { Link  , useParams} from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faNetworkWired,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../Shared/loader";

const ActiveVulnerabilityDetail = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const { vulnerabilityId } = useParams();
  const [loading, setLoading] = useState(false);

  // Retrieve vulnerability stats from Redux
  const fetched_info = useSelector((state) => state.vulnerability?.av_detail);

  const [vulnInfo, setVulnInfo] = useState({});
  const [isModal, setModal] = useState("");
  const [search, setSearch] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");

  const vendorName = sessionStorage.getItem("vendorName");

  const showSpinner = () => {
    setLoading(true);
  };

  const hideSpinner = () => {
    setLoading(false);
  };

  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );


  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setSearch(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  useEffect(() => {
    const targetId = orgId || id;
    if (fetched_info) {
      fetchActiveVulnerabilityDetail(targetId, vulnerabilityId, dispatch);
    }
  }, [orgId, id, dispatch, vulnerabilityId]);

  useEffect(() => {
    // Show spinner if vulnInfo is empty (no keys)
    if (vulnInfo == {}) {
        showSpinner();
    } else {
        hideSpinner();
    }
    // Set vulnInfo based on fetched_info
    setVulnInfo(fetched_info?.data); // Optional chaining to prevent errors
}, [fetched_info, vulnInfo]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
    }
  };

  const Unsnoozed = (_id) => {
    // let ids = [];
    // ids.push(_id);
    // const data = {
    //   action: "resetAction",
    //   pvaIds: selectedIds == 0 ? ids : selectedIds,
    // };
    // fetchCVEDetailsAction(id, dispatch, currentPage, pageSize, search, category, data);
    // setSelectedIds([]);
  };

  const submit = () => {
    // const data = {
    //   action: action,
    //   pvaIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
    //   actionNote: actionNote,
    //   for: forValue,
    // };
    // fetchCVEDetailsAction(id, dispatch, currentPage, pageSize, search, category, data);
    // setModal("");
    // setSelectedValue("pleaseSelect");
    // setActionNote("");
    // setSelectedIds([]);
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `https://api.brandsek.com/api/cve-details/${id}/excel`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "IP Vulnerability Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const truncateDescription = (description) => {
    if (!description) return ""; // Return empty if description is undefined or null
    const words = description.split(" "); // Split the description into words
    if (words.length <= 30) return description; // If 100 words or less, return it as is
    return words.slice(0, 30).join(" ") + "..."; // Join first 100 words and add ellipsis
  };

  return (
    <>
    {loading && <Loader />}
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Active Vulnerability Assessment</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/active-vulnerabilities">
                      Active vulnerabilies
                    </Link>
                  </li>
                  {vendorName ? (
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{}}
                    >
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
              </nav>
            </div>
          </div>
        </div>
        {/* <h6>
          <pre>{JSON.stringify(vulnInfo, null, 2)}</pre>
        </h6> */}
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card white-box-dashboard">
              <div className="body">
                <div className="header">
                  <h6>Active vulnerability information</h6>
                </div>
              </div>
              <div className="col-lg-12 col-md-8">
                <div className="row">
                  <div className="col-lg-5 col-md-5">
                    <div className="card w_card3 card-box">
                      <FontAwesomeIcon icon={faFileSignature} />{" "}
                      <strong className="m-1">Finding:</strong>
                      {vulnInfo?.title ? vulnInfo?.title : "N/A"}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="card w_card3 card-box">
                      <FontAwesomeIcon icon={faNetworkWired} />{" "}
                      <strong className="m-1">Endpoint:</strong>
                      {vulnInfo?.endpoints?.[0] || "N/A"}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="card w_card3 card-box">
                      <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                      <strong className="m-1">Risk:</strong>{" "}
                      <span className="av-risk-critical">
                        {vulnInfo?.severity ? vulnInfo?.severity : "N/A"} (CVSS
                        Score:{" "}
                        {vulnInfo?.cvssv3_score
                          ? vulnInfo?.cvssv3_score
                          : "N/A"}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="av-content">
                <div className="av-content-section">
                  <h3>CVE Id</h3>
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingLeft: "20px",
                      margin: "0",
                    }}
                  >
                    {vulnInfo?.vulnerability_ids &&
                    vulnInfo.vulnerability_ids.length > 0 ? (
                      vulnInfo.vulnerability_ids.map((vuln, index) => (
                        <li key={index}>{vuln.vulnerability_id}</li>
                      ))
                    ) : (
                      <li>No vulnerability ID found</li>
                    )}
                  </ul>
                </div>

                {vulnInfo?.endpoints?.length > 1 && (
                <div className="av-content-section">
                    <h3>Endpoints</h3>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px', margin: '0' }}>
                    {vulnInfo.vulnerability_ids?.length > 0 ? (
                        vulnInfo.endpoints.slice(1).map((vuln, index) => (
                        <li key={index}>{vuln}</li>
                        ))
                    ) : (
                        <li>No Endpoint found</li>
                    )}
                    </ul>
                </div>
                )}

                <div className="av-content-section">
                  <h3>Description</h3>
                  <p>{vulnInfo?.description ? vulnInfo?.description : "N/A"}</p>
                </div>

                <div className="av-content-section">
                  <h3>Impact</h3>
                  <p>{vulnInfo?.impact ? vulnInfo?.impact : "N/A"}</p>
                </div>

                <div className="av-content-section">
                  <h3>Recommendation</h3>
                  {vulnInfo?.mitigation ? vulnInfo?.mitigation : "N/A"}
                </div>

                <div className="av-content-section">
                  <h3>Reference</h3>
                  <p>
                    {vulnInfo?.references ? (
                      <a
                        href={vulnInfo.references}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {vulnInfo.references}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix"></div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-6 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginRight: "28px" }}
                      >
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          onClick={submit}
                          className="btn btn-round btn-primary mr-1 pull-right"
                        >
                          Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn btn-round btn-default pull-right"
                          style={{ marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveVulnerabilityDetail;
