import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import {
//   fetchPastebinResult,
//   fetchPastebinResultGraph,
//   // fetchDarkWebAction,
//   fetchPastebinResultTotal,
// } from "../../actions/pastebin";
import { fetchDarkWebResult, fetchDarkWebAction } from "../../actions/darkweb";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import Fancybox from "../../utils/Fancybox";

const DarkWeb = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const state = useSelector((state) => state?.darkweb?.response);
  const totalPaginationCount = useSelector(
    (state) => state?.darkweb?.response?.totalCount
  );
  const totalRes = useSelector(
    (state) => state?.pastebin?.pasteTotal?.data?.totalPastebinRecords
  );
  const scanCompleted = useSelector((state) => state?.pastebin?.scanCompleted);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [totalItem, setTotalItem] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  let oldDnsDetails = useSelector(
    (state) => state.pastebin?.pasteGraph?.data?.data
  );

  const auth = useSelector((state) => state?.auth?.user);

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        darkWebForumIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchDarkWebAction(id, dispatch, page, data, category);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      darkWebForumIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchDarkWebAction(id, dispatch, page, data, category);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      darkWebForumIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchDarkWebAction(id, dispatch, page, data, category);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  let ipArray = [];
  for (
    let details = 0;
    details < oldDnsDetails?.scanDetails?.length;
    details++
  ) {
    ipArray.push(oldDnsDetails?.scanDetails[details]?.length);
  }

  let totalIp = 0,
    plainPasswords = 0;
  if (oldDnsDetails?.scanDetails) {
    totalIp = oldDnsDetails?.ipLength || 0;
    plainPasswords = oldDnsDetails?.plainPassword || 0;
  }

  useEffect(() => {
    fetchDarkWebResult(id, dispatch, page, category);
  }, [id, dispatch, page, category]);

  useEffect(() => {
    setTotalItem(totalPaginationCount);
  }, [totalPaginationCount]);

  let response = state?.data;

  let mapData = response;

  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/darkwebForum/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Dark Web.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Dark Web Monitoring</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Dark Web Monitoring
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : </b>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            {client?.subscriptionType != "trial" && mapData?.length !== 0 && (
              <span>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                    // exportToCSV(downloadCsvData, "PastebinDetails");
                  }}
                  href="/add-report"
                  className="btn btn-primary mr-1"
                  style={{  fontWeight: "bold" }}
                >
                  <i
                    className="fa fa-file-excel-o mr-1"
                    style={{  }}
                  ></i>{" "}
                  Download Details
                </a>
              </span>
            )}
          </div>
        </div>
      </div>

      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card w_card3 card-box">
          <div className="d-flex justify-content-between">
              <i className="font-50 fa fa-database icon-dashboard"></i>
              <h4 className="font-44 total-h4">
              {state?.totalCount || 0}
              </h4>
              </div>
              <label className="mb-0 text-right">Records Found</label>
              <hr className="border-red"></hr>

            <div className="body pt-1" style={{   }}>
                 {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "460px" }}>
              <h6>Previous scan details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={ipArray}
                  color={["#DD3731"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${
              category === "all" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setSelectedIds([]);
              setSearch("");
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "risk-accepted" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "false-positive" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "remidiated" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
      <div className="card">
        <div className="body">
          <div className="row">
            {/* <div className="col-sm-4">
              <div
                data-test="datatable-input"
                class="mdb-datatable-filter flex-row"
              >
                <input
                  class="form-control form-control-sm ml-0 my-1"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div> */}
            <div className="col-sm-4">
              {selectedIds?.length > 1 && category === "all" && (
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      onChange={(event) => {
                        setAction(event.target.value);
                        snooze("", event.target.value);
                      }}
                    >
                      <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                      <MenuItem
                        value="riskAccepted"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        Risk Accepted
                      </MenuItem>
                      <MenuItem
                        value="markAsFalsePositive"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        False Positive
                      </MenuItem>
                      <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {selectedIds.length > 1 &&
                (category == "false-positive" ||
                  category == "risk-accepted") && (
                  <button
                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                    onClick={() => Unsnoozed("")}
                  >
                    Unsnooze
                  </button>
                )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-12 col-sm-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                        <th style={{ width: "100px" }}>Title</th>
                        {/* <th>Thread link</th> */}
                        <th>Author name</th>
                        <th>Date</th>
                        {/* <th>Fixed screenshot path</th> */}
                        <th>Screenshot Path</th>
                        <th>Source</th>
                        <th style={{ width: "550px", wordWrap: "break-word", wordBreak: "break-word" }}>Summary</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                      </tr>
                    </thead>  
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={5}>
                            {/* <NoData /> */}
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map((row, index) => {
                          return (
                            <tr key={index}>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(row?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                              <td
                                style={{ whiteSpace: "unset", width: "350px" }}
                              >
                                <a
                                  href={row?.thread_link}
                                  target="_blank"
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {row?.title || "N/A"}
                                </a>
                              </td>
                              {/* <td
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <a href={row?.thread_link}>
                                  {row?.thread_link || "N/A"}
                                </a>
                              </td> */}
                              <td>
                                <a href={row?.author_url} target="_blank">
                                  {row?.author_name}
                                </a>
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                <span className="badge badge-success">
                                  {moment(
                                    row?.date,
                                    "DD-MM-YYYY hh:mm A"
                                  ).format("DD-MM-YYYY") || "N/A"}
                                </span>
                              </td>

                              <td
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <Fancybox
                                  options={{
                                    Carousel: {
                                      infinite: false,
                                    },
                                  }}
                                >
                                  <a
                                    data-fancybox="gallery"
                                    href={row?.fixed_screenshot_path}
                                  >
                                    <img
                                      alt=""
                                      src={row?.fixed_screenshot_path}
                                      width="80"
                                      height="80"
                                    />
                                  </a>
                                </Fancybox>
                                {/* <ReactFancyBox
                                  thumbnail={row?.fixed_screenshot_path}
                                  image={row?.fixed_screenshot_path}
                                /> */}
                              </td>
                              {/* <td
                                style={{
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                              >
                                <a href={row?.fullpage_screenshot_url} 
                                className="badge badge-warn"
                                target="_blank">
                                  Fullpage screenshot url
                                </a>
                              </td> */}
                              <td>{row?.source || "N/A"}</td>
                              <td style={{ whiteSpace: "unset", width: "550px" }}>
                                <p style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                  }}>{row?.summary || "N/A"}</p>
                              </td>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(row?._id, event.target.value);
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                              {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItem > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItem}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{  fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModal("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DarkWeb;
