import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import SubscriberChart from "../common/dashboard9/sourceCodeChart";
import BasicLineColumn from "../common/apexChart/lineChart";
import {
  fetchLeakedCode,
  updateSourceCodeStatus,
  fetchLeakedCodeGraph,
  fetchLeakedCodeTotal,
  fetchLeakedCodeAction,
} from "../../actions/leakedCode";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import Dropdown from "react-bootstrap/Dropdown";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SourceCodeLeakage = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const auth = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const [select, setSelect] = useState("");
  const [filterData, setfilterData] = useState("all");
  const state = useSelector((state) => state?.leakedCode?.data?.data?.data);
  const paginationTotal = useSelector((state) => state?.leakedCode?.data?.data);
  const scanCompleted = useSelector(
    (state) => state?.leakedCode?.scanCompleted
  );
  const [isModal, setModal] = useState("");
  const [repo, setRepo] = useState([]);
  const scanDetails = useSelector(
    (state) => state?.leakedCode?.sourceCodeGraph?.data
  );
  const totalRes = useSelector(
    (state) => state?.leakedCode?.sourceCodeTotal?.data
  );
  const [category, setCategory] = useState("all");
  let objArray = {};
  for (let count = 0; count < state?.length; count++) {
    let createdAt = state[count].createdAt.split("T")[0];
    if (objArray[createdAt]) {
      objArray[createdAt].push(state[count]);
    } else {
      objArray[createdAt] = [state[count]];
    }
  }
  let verifiedCode = [],
    unverifiedCode = [];
  let dateKey = [];
  Object.keys(objArray || {}).map((key) => {
    dateKey.push(key);
    let value = objArray[key];
    let verifiedData = value?.filter((val) => val?.isSuspicious);
    let unverifiedData = value?.filter((val) => !val?.isSuspicious);
    verifiedCode.push(verifiedData?.length || 0);
    unverifiedCode.push(unverifiedData?.length || 0);
  });
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const totalCount = state?.filter((row) => !row?.isFalsePositive);
  const verified = totalCount?.filter((row) => row?.isSuspicious);
  const verifiedPercent =
    ((verified?.length || 0) / (totalCount?.length || 0)) * 100;
  const unverified = totalCount?.filter((row) => !row?.isSuspicious);
  const linkdown = totalCount?.filter((row) => row?.isLinkDown);
  const falsePositive = state?.filter((row) => row?.isFalsePositive);
  const linkdownPercent =
    ((linkdown?.length || 0) / (totalCount?.length || 0)) * 100;
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  //console.log(scanCompleted,"SourceCodeLeakagedetails", scanDetails);

  useEffect(() => {
    fetchLeakedCode(id, dispatch, page, select, category);
  }, [id, dispatch, page, PageSize, select, category]);

  useEffect(() => {
    setTotalItems(paginationTotal?.totalLeakedCodes);
  }, [paginationTotal?.totalLeakedCodes]);

  useEffect(() => {
    fetchLeakedCodeGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchLeakedCodeTotal(id, dispatch);
  }, [id, dispatch]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedCodeIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchLeakedCodeAction(id, dispatch, page, select, category, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      leakedCodeIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchLeakedCodeAction(id, dispatch, page, select, category, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        leakedCodeIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchLeakedCodeAction(id, dispatch, page, select, category, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  let mapData = state?.filter((row) => !row?.isFalsePositive);

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `https://api.brandsek.com/api/approved-code?excel=true&orgId=${id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SourceCode Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const editModal = (row) => {
    setRepo(row);
    // setRepo(Array.isArray(row.repository) ? row.repository : [row.repository]);
    // setRepo(Array.isArray(row) ? row : []);
    // console.log(repo);
    setModal("Form Wizard modal value");  
    // if(row){
    //   console.log("row",row);
    // }
    // else{
    //   console.log("nothing")
    // }
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Source Code Leakage</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li className="breadcrumb-item active" aria-current="page">
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Source Code Leakage
                  </li>
                </ol>
                <br />
                <span>
                  <b>Rating : {totalRes?.individualRating}</b>
                </span>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {client?.subscriptionType != "trial" && state?.length !== 0 && (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    style={{
                      float: "right",
                      
                      fontWeight: "bold",
                    }}
                    href="/add-report"
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o  mr-1"
                      style={{  }}
                    ></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-4 col-md-12">
            <div className="card  card-box">
            <div className="d-flex justify-content-between">
              <i className="font-50 fa fa-database icon-dashboard"></i>
              <h4 className="font-44 total-h4">
              {totalRes?.totalLeakedCodes}
              </h4>
              </div>
              <label className="mb-0 text-right">Total Records</label>
              <hr className="border-red"></hr>
              <div className="body pt-1" style={{   }}>
                {/* <div className="header">
                  <h2>Details</h2>
                </div> */}
                 
                <div className="form-group list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => setfilterData("verified")}
                  >
                    Verified Records{" "}
                    <span className="float-right">
                      {(totalRes?.totalVerifiedLeakedCodes || 0)?.toFixed(0)}%
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-success"
                      role="progressbar"
                      aria-valuenow={totalRes?.totalVerifiedLeakedCodes?.toFixed(
                        0
                      )}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width:
                          totalRes?.totalVerifiedLeakedCodes?.toFixed(0) + "%",
                      }}
                    ></div>
                  </div>
                </div>

                <div className="form-group  list-with-number">
                  <label
                    className="d-block"
                    style={{ cursor: "pointer" }}
                    onClick={() => setfilterData("takedown")}
                  >
                    Records Taken Down{" "}
                    <span className="float-right">
                      {(totalRes?.totalTakenDownLeakedCodes || 0)?.toFixed(0)}%
                    </span>
                  </label>
                  <div className="progress progress-xxs">
                    <div
                      className="progress-bar progress-bar-success"
                      role="progressbar"
                      aria-valuenow={totalRes?.totalTakenDownLeakedCodes?.toFixed(
                        0
                      )}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width:
                          totalRes?.totalTakenDownLeakedCodes?.toFixed(0) + "%",
                      }}
                    ></div>
                  </div>
                </div>
                {client?.subscriptionType == "trial" && (
                  <div className="card border-warning">
                    <TrialComponent />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="card  white-box-dashboard">
              <div className="body" style={{ minHeight: "400px" }}>
                <h6>Previous scan details</h6>
                {scanDetails && (
                  <BasicLineColumn
                    dates={scanDetails?.dates}
                    ipArray={scanDetails?.scanDetails}
                    color={["#DD3731"]}
                    yAxis="Records Found"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
          <li className="nav-item">
            <a
              className={`nav-table-tab nav-link ${
                category === "all" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("all");
                setPage(1);
                setSelect("all");
                setSelectedIds([]);
              }}
            >
              Current
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${
                category === "risk-accepted" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("risk-accepted");
                setPage(1);
                setSelect("all");
                setSelectedIds([]);
              }}
            >
              Risk Accepted
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${
                category === "false-positive" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("false-positive");
                setPage(1);
                setSelect("all");
                setSelectedIds([]);
              }}
            >
              False Positive
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link nav-table-tab ${
                category === "remidiated" ? "active" : ""
              }`}
              onClick={() => {
                setCategory("remidiated");
                setPage(1);
                setSelect("all");
                setSelectedIds([]);
              }}
            >
              Remediated
            </a>
          </li>
        </ul>
        <div className="card">
          <div className="body">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => {
                        setSelect(e.target.value);
                        setfilterData(e.target.value);
                        setPage(1);
                      }}
                    >
                      <option value="all">All</option>
                      <option value="verified">Verified</option>
                      <option value="unverified">Unverified</option>
                      <option value="takedown">takedown</option>
                      <option value="falsePositive">FalsePositive</option>
                    </select>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                        <th>#</th>
                        {auth.accessLevel !== "Client" && <th>Status</th>}
                        {/* <th style={{ width: "40" }}>Platform</th> */}
                        <th>Repository Link 4</th>
                        <th>Last Update at</th>
                        <th>Created at</th>
                        <th>Detail</th>
                        {/* <th>Actions</th> */}
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={5}>
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map((res, index) => (
                          <tr key={index}>
                            {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                              category === "risk-accepted" ||
                              category === "false-positive") && (
                              <td>
                                <input
                                  onClick={() => singleClick(res?._id)}
                                  checked={selectedIds?.includes(res?._id)}
                                  type="checkbox"
                                  className="checkUncheck"
                                />
                              </td>
                            )}
                            <td style={{ whiteSpace: "unset" }}>
                              <span>{index + 1}</span>
                            </td>
                            {auth.accessLevel !== "Client" && (
                              <th>
                                {" "}
                                <span
                                  className={
                                    res?.isSuspicious
                                      ? "badge badge-success"
                                      : "badge badge-danger"
                                  }
                                >
                                  {res?.isSuspicious
                                    ? "Verified"
                                    : "Unverified"}
                                </span>
                                {res?.isLinkDown && (
                                  <span className="badge badge-warning">
                                    Link down
                                  </span>
                                )}
                              </th>
                            )}

                            {/* <td style={{ whiteSpace: "unset" }}>
                              <i className="fa fa-github"></i>
                              GitHub
                            </td> */}
                            {/* <td>{res?.keyword||"-"}</td> */}
                            <td style={{ whiteSpace: "unset" }}>
                              <a
                                href={`${res?.repoUrl}`}
                                target="_blank"
                                style={{  }}
                              >
                                {res?.repoUrl}
                              </a>
                            </td>
                            {/* <td style={{ whiteSpace: "unset" }}>{res?.leakedCode||"-"}</td> */}
                            {/* <td>Screenshot</td> */}
                            <td>
                              {" "}
                              {res?.updatedAt
                                ? moment(res?.updatedAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                : res?.createdAt
                                ? moment(res?.createdAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                : "NA"}
                            </td>
                            <td>
                              {" "}
                              {res?.date
                                ? moment(res?.date).format("DD-MM-YYYY HH:MM")
                                : res?.createdAt
                                ? moment(res?.createdAt).format(
                                    "DD-MM-YYYY HH:MM"
                                  )
                                : "NA"}
                            </td>
                            <td
                              onClick={() => editModal(res?.repository)}
                              style={{ cursor: "pointer" }}
                            >
                              <span>Detail</span>
                            </td>
                            {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                              <td>
                                <Box sx={{ width: 200 }}>
                                  <FormControl fullWidth>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={selectedValue}
                                      onChange={(event) => {
                                        setAction(event.target.value);
                                        snooze(res?._id, event.target.value);
                                      }}
                                    >
                                      <MenuItem value="pleaseSelect">
                                        Snooze Options
                                      </MenuItem>
                                      <MenuItem
                                        value="riskAccepted"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([res._id]);
                                        }}
                                      >
                                        Risk Accepted
                                      </MenuItem>
                                      <MenuItem
                                        value="markAsFalsePositive"
                                        onClick={() => {
                                          addModal();
                                          setLookalikeAppId([res._id]);
                                        }}
                                      >
                                        False Positive
                                      </MenuItem>
                                      <MenuItem value="markAsRemidiated">
                                        Remediated
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </td>
                            )}
                            {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                              category === "false-positive") && (
                              <td>
                                <button
                                  className="btn btn-primary btn-sm mr-1 Unsnooze"
                                  onClick={() => Unsnoozed(res?._id)}
                                >
                                  Unsnooze
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => {
                        setPage(page);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal value" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Repo URL
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModal("")}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-lg-12 col-md-12">
                        {/* <div className="col-lg-12 col-md-12">
                          {repo?.map((res, index) => (
                            <a
                              href={`${res}`}
                              target="_blank"
                              style={{  }}
                            >
                              <span style={{ marginRight: "10px" }}>
                                {index + 1}.
                              </span>
                              {res}
                            </a>
                          ))}
                        </div> */}
                        <div className="col-lg-12 col-md-12">
  {console.log("repo:", repo)}
  {Array.isArray(repo) && repo.length > 0 ? (
    <ul style={{ listStyleType: "none", padding: 0 }}>
      {repo.map((res, index) => (
        <li key={index} style={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "10px" }}>
            {index + 1}.
          </span>
          <a
            href={`${res}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }} // Optional: removes underline from links
          >
            {res}
          </a>
        </li>
      ))}
    </ul>
  ) : (
    <p>No data available</p>
  )}
</div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="">
                  <div className="card mb-0" >
                    <div className="row clearfix">
                       
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                       
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceCodeLeakage;
