import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $ from "jquery";
import { fetchUsers, createUser, updateUserById, deleteUser } from '../../actions/users';
import {
  fetchCustomersNew,
  sendUserInvite
} from "../../actions/customer";
import {
  IMAGE_URL
} from "../../utils/constant";
import { countryCodes } from "../../utils/countryCode";
import { toastr } from "react-redux-toastr";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

const InviteUser = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  let users = useSelector((state) => state?.users?.response?.data);
  let userss = useSelector((state) => state);
  console.log(users,'users')
  const authUser = useSelector(
    (state) => state?.auth?.user
  );
  let customers = useSelector((state) => state?.customer?.response?.data);
  console.log(userss)
  let selectedClient;
  if(id){
    selectedClient = customers?.filter((val) => val._id == id);
    selectedClient = selectedClient?.[0] || {};
  }else{
    selectedClient = authUser?.organisationId || {};
  }
  // console.log(selectedClient,'selectedClient')
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [accessData,setAccessData] = useState([
    { id: 1, val: "Admin", text: "Admin" },
    { id: 2, val: "Threat Analyst", text: "Threat Analyst" },
  ]);
  const [isModal, setModal] = useState("");
  const [reminderModal, setReminderModal] = useState(false);
  const [emailId, setEmailId] = useState("");

  const [primaryContactName, setContactName]= useState("");
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [orgAdmin, setOrgAdmin] = useState(false);
  const [services, setServices] = useState([]);
  const [email, setEmail]= useState("");
  const [phone, setPhone]= useState("");
  const [accessValue, setAccessLevel]= useState(accessData[0].val)
  const [loading, setLoading] = useState(true);
  
  if(history.location.pathname.indexOf("vendor") != -1 && loading){
    setAccessData([{ id: 4, val: "Vendor", text: "Vendor" }])
    setAccessLevel("Vendor");
    setLoading(false)
  }else{
    if(authUser && loading){
      if(authUser.accessLevel == "Client"){
      // const [accessData] = useState([
      //   { id: 1, text: "Client" }
      // ]);
      // setAccessData([{ id: 3, val: "Client", text: "Administrator" }])
      setAccessData([{ id: 3, val: "Client", text: "Administrator" },{ id: 5, val: "Manager", text: "Manager" }])
      setAccessLevel("Client");
      setLoading(false)
      }else{
        setLoading(false)
      }
    }
    if(id && loading){
      setAccessData([{ id: 3, val: "Client", text: "Administrator" },{ id: 5, val: "Manager", text: "Manager" }])
      setAccessLevel("Client");
      setLoading(false);
    }
  }
  // console.log(authUser,'authUser')
  useEffect(() => {
    fetchCustomersNew(dispatch);
    fetchUsers(props.match.params.id || authUser?.organisationId?._id,dispatch);
  }, []);

  // console.log(accessValue,'users')

  const downloadCsvData = users?.map((res) => ({
    fullName: res?.fullName,
    EmailAddress: res?.email,
    ContactNumber: res?.contact || "NA",
    Role: res?.accessLevel,
    PasswordReset: res?.passwordReset ? "Yes" : "No",
    RegistrartionDate: moment(res?.website).format(
      "DD/MM/YYYY hh:mm a"
    )
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  let mapData = search == "" 
  ? users
  : users?.filter((row) => ((row?.fullName?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1) || row?.email?.toLowerCase()?.indexOf(search?.toLowerCase()) != -1))

  const submitForm = () => {
    if(primaryContactName == ""){
      toastr.error("Error", "Please enter name.");
      return false;
    }
    if(email == ""){
      toastr.error("Error", "Please enter email.");
      return false;
    }
    var EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!EmailRegex.test(email)){
      toastr.error("Error", "Please enter valid email id.");
      return false;
    }
   
    // if(phone == ""){
    //   toastr.error("Error", "Please enter phone number.");
    //   return false;
    // }

    let _services = [];
    if(accessValue == "Client"){
      _services = selectedClient?.services || [];
    }

    if(isEdit){
      updateUserById(
        {
          id: userId,
          fullName: primaryContactName,
          email: email,
          contact: phone,
          twoFactorEnabled: twoFactorEnabled,
          orgAdmin: accessValue == "Client" ? true : false,
          services: accessValue == "Client" ? _services : services,
          accessLevel: accessValue,
          countryCode: countryCode,
          orgId:id
        },
        dispatch
      );
    }else{
      createUser(
        {
          fullName: primaryContactName,
          contact: phone,
          accessLevel: accessValue,
          twoFactorEnabled: twoFactorEnabled,
          orgAdmin: accessValue == "Client" ? true : false,
          services: accessValue == "Client" ? _services : services,
          email: email,
          countryCode: countryCode,
          organisationId: id || ""
        },
        dispatch,
        history
      );
    }

    setModal("")
  }

  const addModal = () => {
    setIsEdit(false);
    setContactName("")
    setEmail("")
    setPhone("")
    setCountryCode("")
    setAccessLevel(accessData[0].val)
    setModal("Form Wizard modal")
  }

  const editModal = (row) => {
    //console.log(row,'row')
    setIsEdit(true);
    setContactName(row?.fullName)
    setEmail(row?.email)
    setPhone(row?.contact)
    setCountryCode(row?.countryCode);
    setAccessLevel(row?.accessLevel)
    setTwoFactorEnabled(row?.twoFactorEnabled);
    setServices(row?.services);
    // setOrgAdmin(row?.orgAdmin || false);
    setUserId(row?._id)
    setModal("Form Wizard modal")
  }

  const  deleteUserById = (id) => {
    // const self = this;
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to delete this customer?",
      type: "red",
      buttons: {
        ok: {
          text: "Delete",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            deleteUser(id,dispatch);
            // self.setState({
            //   deleting: true,
            // });
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const sendReminder = () => {
    setReminderModal(false)
    sendUserInvite({
        email: emailId
    })
}

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2 style={{ }}>Invite User</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page" style={{ }}>
                    Invite User
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <Link to="/" className="btn btn-sm btn-primary mr-1" title="">
                Create Campaign
              </Link>
              <Link
                to="https://themeforest.net/item/BrandSek-bootstrap-4x-admin-dashboard-clean-modern-ui-kit/23091507"
                className="btn btn-sm btn-success"
                title="Themeforest"
              >
                <i className="icon-basket"></i> Buy Now
              </Link>
            </div> */}
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-12">
            <div className="card mb-2">
              <div className="body">
                <div className="row">
                {authUser?.accessLevel != "Threat Analyst" && (
                  <div className="col-lg-2 col-md-4 col-sm-12">
                    <button
                      type="button"
                      style={{fontWeight:"bold"}}
                      className="btn btn-sm btn-primary"
                      onClick={() => addModal()}
                    >
                    <i className="fa fa-plus mr-1"></i> Add User
                    </button>
                  </div>
                )}
                  <div className="col-lg-3 col-md-6">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="name, email address"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={authUser?.accessLevel != "Threat Analyst" ? "col-lg-7 col-md-4 col-sm-12" : "col-lg-9 col-md-4 col-sm-12"}>
                  {users?.length === 0 ? null : (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        exportToCSV(downloadCsvData, "User List");
                      }}
                      style={{ float: "right" }}
                      href="/add-report"
                      className="btn btn-primary mr-1"
                    >
                      <i className="fa fa-file-excel-o " style={{ fontWeight:"bold"}}></i>
                    </a>
                  )}
                </div>

                  {/* <div className="col-lg-3 col-md-6">
                    <a
                      href="/"
                      className="btn btn-sm btn-primary btn-block"
                      title=""
                    >
                      Search
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-custom spacing5 mb-0">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Contact Number</th>
                    <th>Role</th>
                    <th>Password Reset Status</th>
                    <th>Registeration Date</th>
                    {authUser?.accessLevel != "Threat Analyst" && (
                      <th>Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {mapData?.map((item) => {
                    return(
                      <tr>
                        <td>
                          <img
                            src={
                              item.avatar
                                ? `${IMAGE_URL}${item.avatar}`
                                : "../assets/images/xs/profile.png"
                            }
                            alt="Avatar"
                            className="w30 rounded-circle mr-2"
                          />{" "}
                          <span>{item.fullName}</span>
                        </td>
                        <td>
                          <h6 className="mb-0">{item.email}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0">{item.contact}</h6>
                        </td>
                        <td>
                          <h6 className="mb-0">{item.accessLevel}</h6>
                        </td>
                        <td>
                          <span className="badge badge-warning">{item.passwordReset ? "Yes" : "No"}</span>
                        </td>
                        <td>
                          <h6 className="mb-0">{moment(item.createdAt).format(
                                  "DD/MM/YYYY hh:mm a"
                                )}</h6>
                        </td>
                        {authUser?.accessLevel != "Threat Analyst" && (
                          <td>
                            <button className="btn btn-primary mr-1" onClick={() => editModal(item)} style={{}}>
                              Edit
                            </button>
                            <button className="btn btn-secondary" onClick={() => deleteUserById(item?._id)}>
                              Delete
                            </button>
                            {
                              authUser?.accessLevel != "Threat Analyst" &&  !item.passwordReset && 
                                <i style={{marginLeft:"20px",fontSize:'20px',cursor:"pointer"}} onClick={() => {setReminderModal(true); setEmailId(item.email) }} class="fas fa-bell"></i>
                            }
                          </td>
                        )}
                      </tr>
                    )
                  })}
                  {/* <tr>
                    <td>
                      <img
                        src="../assets/images/xs/avatar1.jpg"
                        alt="Avatar"
                        className="w30 rounded-circle mr-2"
                      />{" "}
                      <span>Isidore Dilao</span>
                    </td>
                    <td>
                      <h6 className="mb-0">account@something.com</h6>
                    </td>
                    <td>
                      <h6 className="mb-0">+1 (561) 289-0454</h6>
                    </td>
                    <td>
                      <h6 className="mb-0">Threat Analyst</h6>
                    </td>
                    <td>
                      <span className="badge badge-success">Completed</span>
                    </td>
                    <td>
                      <h6 className="mb-0">22/05/2022 14:14</h6>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-sm mr-1">
                        Edit
                      </button>
                      <button className="btn btn-danger btn-sm">
                        Delete
                      </button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add New User
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModal("")}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page">
                <div className=" ">
                  <div className="card mb-0">
                  <div className="row clearfix">
                      <div className="col-md-6">
                        <label className=" ">Primary Contact Name</label>
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Primary Contact Name *"
                            value={primaryContactName}
                            onChange={(e) => setContactName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className=" ">Email address</label>
                        <div className="form-group ">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email address *"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label  >Phone</label>
                        <div className="form-group " style={{display:"flex"}}>
                          <select className="form-control" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} style={{width:'100px'}}>
                            {countryCodes?.map((val) => {
                              return <option value={val.dial_code}>{val.name}</option>
                            })}
                          </select>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label className=" ">Access Level</label>
                        <div className="form-group ">
                          <select className="form-control" 
                              value={accessValue}
                              onChange={(e) => setAccessLevel(e.target.value)}
                          >
                            {/* <option value="">-- Email Access * --</option> */}
                            {accessData?.map((row) => {
                              return(
                                <option value={row.val}>{row.text}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-12">
                        <label className="col-lg-6 col-md-12">Two Factor Enabled</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <label className="switch">
                            <input type="checkbox" className="lv-btn" onChange={() => setTwoFactorEnabled(!twoFactorEnabled)} checked={twoFactorEnabled ? "checked" : ""} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div> */}
                      
                      {/* <div className="col-lg-6 col-md-12">
                        <label className="col-lg-6 col-md-12">Organisation Admin</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <label className="switch">
                            <input type="checkbox" className="lv-btn" onChange={() => setOrgAdmin(!orgAdmin)} checked={orgAdmin ? "checked" : ""} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div> */}

                      {accessValue == "Manager" && 
                        <div className=" col-md-6">
                          <label className=" ">Module Access</label>
                          <div className="form-group ">
                            <Autocomplete
                              style={{ width: "100%" }}
                              multiple
                              id="tags-standard"
                              options={selectedClient?.services || []}
                              value={services}
                              onChange={(event, newValue) => {
                                setServices(newValue);
                              }}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  // label="Multiple values"
                                  placeholder=""
                                />
                              )}
                            />
                          </div>
                        </div>
                      }

                      {/* <div className="col-lg-6 col-md-12">
                      </div> */}
                      
                    </div>
                     
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                           
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submitForm}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>

        <div
                className={`modal fade Form-Wizard-modal ${reminderModal ? "d-block show" : ""
                    }`}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Reminder
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Do you really want to send reminder to this user?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-round btn-default"
                                data-dismiss="modal"
                                onClick={() => setReminderModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-round btn-primary"
                                style={{  fontWeight: "bold" }}
                                onClick={() => {
                                    sendReminder()
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </>
  );
};
export default InviteUser;
