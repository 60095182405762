import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from './utils/utils';
import ResetPassword from "./components/Authentication/resetPassword";

export const PrivateRoute = ({component: Component, auth, ...rest}) => {
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            isLogin() ? auth.user && auth.user.passwordReset ?<Component {...props} auth={auth} />:
             <ResetPassword {...props} auth={auth} /> : <Redirect to="/login" />
        )} />
    );
};

export const PublicRoute = ({component: Component, auth, restricted, ...rest}) => {
    console.log(">>>>>>>>>>> src route");
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLogin() && auth.user && auth.user.passwordReset && restricted ?
                <Redirect to={(auth.user.accessLevel == "Threat Analyst") ? "/" : "/"} /> // Earlier Threat Analyst pointing to /clients
            : <Component {...props} auth={auth} />
        )} />
    );
};