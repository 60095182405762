import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
import ScanMessage from "../common/scanningMessage";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSubDomains,
  fetchSubDomainsTotal,
  fetchSubDomainsGraph,
  fetchSubDomainsAction,
} from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/basicLineColumn";
import AccessPage from "../Shared/accessPage";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useHistory } from 'react-router-dom';

const Dashboard5 = () => {
  const history = useHistory();
  let subdomains = useSelector(
    (state) => state?.subDomain?.responseSsl?.data
  );
  let subdomainsTotals = useSelector(
    (state) => state?.subDomain?.subDomainSslTotal?.data
  );
  let totalItem = useSelector(
    (state) => state?.subDomain?.responseSsl
  );
  const scanCompleted = useSelector(
    (state) => state?.subDomain?.subDomainScanCompleted
  );
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);

  const [firstLoad, setFirstLoad] = useState(true);
  const [riskScore, setRiskScore] = useState("");

  const [expiryLoad, setExpiryLoad] = useState(true);
  const [riskExpiryScore, setExpiryRiskScore] = useState("");

  const [filterData, setfilterData] = useState("all");
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");

  const myRef = useRef(null);

  const executeScroll = (val) => {
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  const dispatch = useDispatch();

  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (orgId) {
      fetchSubDomains(orgId, dispatch, page, category, search, filterData);
    } else {
      fetchSubDomains(id, dispatch, page, category, search, filterData);
    }
  }, [id, dispatch, page, category, search, filterData]);

  useEffect(() => {
    setTotalItems(totalItem?.totalResults);
  }, [totalItem?.totalResults]);

  useEffect(() => {
    fetchSubDomainsTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchSubDomainsGraph(id, dispatch);
  }, [id, dispatch]);

  let currentTableData = [];

  const auth = useSelector((state) => state?.auth?.user);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds?.length > 0 || selectedIds?.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds?.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchSubDomainsAction(
        id,
        dispatch,
        page,
        category,
        search,
        filterData,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      subDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchSubDomainsAction(
      id,
      dispatch,
      page,
      category,
      search,
      filterData,
      data
    );
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchSubDomainsAction(
      id,
      dispatch,
      page,
      category,
      search,
      filterData,
      data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/expired-ssl-certificates/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Expired SSL.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  let oldDnsDetails = useSelector(
    (state) => state?.subDomain?.expiredGraph?.data
  );

  let ipArray = [];
  for (
    let details = 0;
    details < oldDnsDetails?.scanDetails?.length;
    details++
  ) {
    ipArray.push(oldDnsDetails?.scanDetails[details]);
  }

  let mapData = subdomains;
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }
  if (mapData && mapData.length > 0) {
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }
  const handleClick = () => {
    history.push(`/vulnerable-certificates${orgId ? "/vendor/" + orgId : ""}`);
  };
  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <ReactTooltip multiline={true} />
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Expired SSL Certificates</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{}}
                    >
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span>
                  <button className="btn btn-outline-primary mr-1" onClick={handleClick}>
                    Vulnerable Certificate
                  </button>
                </span>
                <span>
                  <button className="btn btn-primary mr-1">
                    Expired SSL Certificate
                  </button>
                </span>
                <br/>
                <br/>
                <span>
                  <b>Rating : {subdomainsTotals?.individualRating}</b>
                </span>
                <br></br>
                <span>
                  <b>Total SSL Records : {subdomainsTotals?.totalSSLRecords}</b>
                </span>
              </nav>
              {/* <p>Vulberability Grade : {riskScore || "Calculating..."}</p>
              <p>Expiry Grade : {riskExpiryScore || "Calculating..."}</p> */}
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {mapData?.length === 0 ? null : (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    href="/add-report"
                    className="btn btn-primary mr-1"
                    style={{}}
                  >
                    <i className="fa fa-file-excel-o mr-1"></i> Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}
        {/* <h6><pre>{JSON.stringify(subdomainsTotals,null,2)}</pre></h6> */}
        {/* <h6><pre>{JSON.stringify(currentTableData,null,2)}</pre></h6> */}
        
        <div className="row clearfix">
          <div className="col-lg-12 col-md-8">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("expired")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.totalExpiredSSls}
                        </h5>
                        <label className="text-left">Expired</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {(
                            subdomainsTotals?.totalExpiredSSls /
                            subdomainsTotals?.totalSSLRecords
                          ).toFixed(0) * 100}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-red mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={
                              (
                                subdomainsTotals?.totalExpiredSSls /
                                subdomainsTotals?.totalSSLRecords
                              ).toFixed(0) * 100
                            }
                            aria-valuenow={
                              (
                                subdomainsTotals?.totalExpiredSSls /
                                subdomainsTotals?.totalSSLRecords
                              ).toFixed(0) * 100
                            }
                            style={{
                              width:
                                (
                                  subdomainsTotals?.totalExpiredSSls /
                                  subdomainsTotals?.totalSSLRecords
                                ).toFixed(0) *
                                  100 +
                                "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin30days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.totalSSLsWithRenewalIn30Days}
                        </h5>
                        <label className="text-left">Renewal in 30 days</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.totalSSLsWithRenewalIn30Days /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin60days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.totalSSLsWithRenewalIn60Days}
                        </h5>
                        <label className="text-left">Renewal in 60 days</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.totalSSLsWithRenewalIn60Days /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.totalSSLsWithRenewalIn90Days}
                        </h5>
                        <label className="text-left">Renewal in 90 days</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.totalSSLsWithRenewalIn90Days /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.mismatchedCertificateCount}
                        </h5>
                        <label className="text-left">Mismatched Certificates</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.mismatchedCertificateCount /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.mismatchedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.mismatchedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.mismatchedCertificateCount /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                            
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.revokedCertificateCount}
                        </h5>
                        <label className="text-left">Revoked Certificates</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.revokedCertificateCount /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.revokedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.revokedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.revokedCertificateCount /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.selfSignedCertificateCount}
                        </h5>
                        <label className="text-left">Self Signed Certificates</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.selfSignedCertificateCount /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.selfSignedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.selfSignedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.selfSignedCertificateCount /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.untrustedCertificateCount}
                        </h5>
                        <label className="text-left">Untrusted Certificates</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.untrustedCertificateCount /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.untrustedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.untrustedCertificateCount /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.untrustedCertificateCount /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers}
                        </h5>
                        <label className="text-left">Subdomain supporting weak Ciphers</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.totalSubDomainsHavingCertificatesSupportingWeakCiphers /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="card card-box mr-0">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("renewalin90days")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0 font-34">
                          {subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion}
                        </h5>
                        <label className="text-left">Subdomain with outdated TLS Versions</label>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0 font-red-theme">
                          {Math.floor(
                            (subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion /
                              subdomainsTotals?.totalSSLRecords) *
                              100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            aria-valuenow={Math.floor(
                              (subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion /
                                subdomainsTotals?.totalSSLRecords) *
                                100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (subdomainsTotals?.totalSubDomainsHavingCertificatesWithOutdatedTlsVersion /
                                    subdomainsTotals?.totalSSLRecords) *
                                    100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card white-box-dashboard">
                  <div className="body">
                    <div className="header">
                      <h6>Previous scan details</h6>
                    </div>
                    {oldDnsDetails && ipArray && Array.isArray(ipArray) ? (
                      <BasicLineColumn
                        dates={oldDnsDetails.dates || []} // Default to an empty array
                        ipArray={ipArray}
                        color={["#DD3731"]}
                      />
                    ) : (
                      <p>No data available</p> // Fallback message
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${
                        category === "all" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${
                        category === "risk-accepted" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Risk Accepted
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${
                        category === "false-positive" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("false-positive");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      False Positive
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${
                        category === "remidiated" ? "active" : ""
                      }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Remediated
                    </a>
                  </li>
                </ul>
                <div className="card mb-0" ref={myRef}>
                  <div className="body">
                    <div className="header pb-0">
                      <div className="row">
                        <div className="col-sm-4">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            value={filterData}
                            onChange={(e) => {
                              setfilterData(e.target.value); // Set the filter data
                              setPage(1); 
                            }}
                          >
                            <option value="all">All</option>
                            <option value="expired">Expired</option>
                            <option value="vulnerable">Vulnerable</option>
                            <option value="renewalin30days">
                              Renewal in 30 days
                            </option>
                            <option value="renewalin60days">
                              Renewal in 60 days
                            </option>
                            <option value="renewalin90days">
                              Renewal in 90 days
                            </option>
                          </select>
                        </div>
                        <div className="col-sm-4">
                          <div
                            data-test="datatable-input"
                            class="mdb-datatable-filter flex-row"
                          >
                            <input
                              class="form-control form-control-sm ml-0 my-1"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          {selectedIds?.length > 1 && category === "all" && (
                            <Box sx={{ width: 200 }}>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedValue}
                                  onChange={(event) => {
                                    setAction(event.target.value);
                                    snooze("", event.target.value);
                                  }}
                                >
                                  <MenuItem value="pleaseSelect">
                                    Snooze Options
                                  </MenuItem>
                                  <MenuItem
                                    value="riskAccepted"
                                    onClick={() => {
                                      addModal();
                                    }}
                                  >
                                    Risk Accepted
                                  </MenuItem>
                                  <MenuItem
                                    value="markAsFalsePositive"
                                    onClick={() => {
                                      addModal();
                                    }}
                                  >
                                    False Positive
                                  </MenuItem>
                                  <MenuItem value="markAsRemidiated">
                                    Remediated
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          )}
                          {selectedIds.length > 1 &&
                            (category == "false-positive" ||
                              category == "risk-accepted") && (
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed("")}
                              >
                                Unsnooze
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-custom spacing5 mb-0">
                          <thead>
                            <tr>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedIds == 0 ? false : multiCheckTrue
                                    }
                                    onClick={() => {
                                      multicheck(currentTableData);
                                    }}
                                    className="checkUncheck"
                                  />
                                </th>
                              )}
                              <th>Status</th>
                              <th>Sub-Domain Name</th>
                              <th>Expiry in</th>
                              <th>Country</th>
                              <th>Serial Number</th>
                              <th>Vulnerabilties</th>
                              <th>Last modified on</th>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th style={{ width: "80" }}>Action</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                          {currentTableData?.length > 0 ? (
                            currentTableData?.map((row, index) => {
                              return (
                                <tr key={row?._id}>
                                    {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                      category === "risk-accepted" ||
                                      category === "false-positive") && (
                                      <td>
                                        <input
                                          onClick={() => singleClick(row?._id)}
                                          checked={selectedIds?.includes(row?._id)}
                                          type="checkbox"
                                          className="checkUncheck"
                                        />
                                      </td>
                                    )}
                                    <td>
                                      <span className={row?.status == "Active" ? "badge badge-success" : "badge badge-danger"}>
                                        {row?.status}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="text-warning">
                                        {row?.subDomainName || "NA"}
                                      </span>
                                    </td>
                                    <td>{row?.expiresInDays + " days" || "NA"}</td>
                                    <td>{row?.issuerCountry || "NA"}</td>
                                    <td>{row?.certificateSerialNumber || "NA"}</td>
                                    <td>{row?.status_vulnerability || "None"}</td>
                                    <td style={{ whiteSpace: "unset" }}>
                                      <span className="badge badge-success">
                                        {moment(row?.updatedAt || row?.createdAt).format("DD-MM-YYYY")}
                                      </span>
                                    </td>
                                    {category === "all" && auth?.accessLevel != "Threat Analyst" && (
                                      <td>
                                        <Box sx={{ width: 200 }}>
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={selectedValue}
                                              onChange={(event) => {
                                                setAction(event.target.value);
                                                snooze(row?._id, event.target.value);
                                              }}
                                            >
                                              <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                                              <MenuItem
                                                value="riskAccepted"
                                                onClick={() => {
                                                  addModal();
                                                  setLookalikeAppId([row._id]);
                                                }}
                                              >
                                                Risk Accepted
                                              </MenuItem>
                                              <MenuItem
                                                value="markAsFalsePositive"
                                                onClick={() => {
                                                  addModal();
                                                  setLookalikeAppId([row._id]);
                                                }}
                                              >
                                                False Positive
                                              </MenuItem>
                                              <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </td>
                                    )}
                                    {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                      category === "false-positive") && (
                                      <td>
                                        <button
                                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                                          onClick={() => Unsnoozed(row?._id)}
                                        >
                                          Unsnooze
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="dataTables_paginate">
                        {totalItems > 0 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={totalItems}
                            pageSize={PageSize}
                            onPageChange={(page) => setPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
              <div className="">
              <div className="card mb-0">
                    <div className="row clearfix">
                       
                        <label className="col-lg-12 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard5;
