import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchCVEDetails,
  fetchCVEDetailsGraph,
  fetchCVEDetailsTotal,
  fetchCVEDetailsAction,
  fetchActiveVulnerabilities,
  fetchActiveVulnerabilitiesStats,
  fetchActiveVulnerabilitiesScore,
  fetchActiveVulnerabilityAction,
} from "../../actions/vulnerability";
// import { Link  , useParams} from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Donut } from "react-dial-knob";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ActiveVulnerability = () => {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const [totalItems, setTotalItems] = useState(0);

  const [category, setCategory] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  // const [filterData, setfilterData] = useState("");
  const [risk, setRisk] = useState("");

  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  // const [total, setTable] = useState({});
  const [TotalSeverities, setTotalSeverities] = useState({
    totalHigh: 0,
    totalCritic: 0,
    totalInfo: 0,
    totalLow: 0,
    totalMid: 0,
  });

  const vendorName = sessionStorage.getItem("vendorName");

  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  let table = useSelector((state) => state.vulnerability?.av?.data?.results);

  const auth = useSelector((state) => state?.auth?.user);
  
  const pagination = useSelector(
    (state) => state.vulnerability?.av?.data?.results
  );

  let scanCompleted = useSelector(
    (state) => state.vulnerability?.scanCompleted
  );
  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setSearch(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  // const scanDetails = useSelector(
  //   (state) => state?.vulnerability?.cveGraph?.data
  // );
  // console.log(scanDetails, "scan");

  useEffect(() => {
    const targetId = orgId || id;
    if (targetId) {
      fetchActiveVulnerabilitiesScore(targetId, dispatch);
    }
  }, [id, orgId, dispatch]);

  let avScore = useSelector((state) => state.vulnerability?.av_score?.data?.averageConsolidatedSeverity);

  useEffect(() => {
    const targetId = orgId || id;
    if (targetId) {
      fetchActiveVulnerabilitiesStats(targetId, dispatch);
    }
  }, [id, orgId, dispatch]);

  // Retrieve vulnerability stats from Redux
  const table_stats = useSelector((state) => state.vulnerability?.av_stats?.data);

  // Update severity and item counts when `table_stats` changes
  useEffect(() => {
    if (table_stats) {
      setTotalSeverities({
        totalCritic: table_stats.totalCriticalSeverityRisks || 0,
        totalHigh: table_stats.totalHighSeverityRisks || 0,
        totalMid: table_stats.totalMediumSeverityRisks || 0,
        totalLow: table_stats.totalLowSeverityRisks || 0,
        totalInfo: table_stats.totalInfoSeverityRisks || 0
      });
      setTotalItems(table_stats.totalRisks || 0);
    }
  }, [table_stats]);

  useEffect(() => {
    if (orgId) {
      fetchActiveVulnerabilities(orgId, dispatch, currentPage, search, category, risk, pageSize);
    } else {
      fetchActiveVulnerabilities(id, dispatch, currentPage, search, category, risk, pageSize);
    }
    console.log("called");
  }, [id, dispatch, currentPage, orgId, search, risk, category, currentPage, pageSize]);


  useEffect(() => {
    setTotalItems(pagination?.cveDetailsRecord);
  }, [pagination?.cveDetailsRecord]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item.id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
      console.log("nothing to snooze");
    }

    if (event == "riskAccept") {
      console.log("snooze to accept risk");
    }

    if (event == "falsePositive") {
      console.log("snooze to false positive");
    }

    if (event === "markAsRemidiated") {
      console.log("snooze to mitigate");
      let ids = [];
      ids.push(_id);
      if (_id==="") {
        ids = selectedIds;
      }
      const data = {
        findingsId: selectedIds == 0 ? ids : selectedIds,
        action: "mitigate",
      };
      fetchActiveVulnerabilityAction(id, dispatch, data);
      setSelectedValue("pleaseSelect");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    // let ids = [];
    // ids.push(_id);
    // const data = {
    //   action: "resetAction",
    //   pvaIds: selectedIds == 0 ? ids : selectedIds,
    // };
    // fetchCVEDetailsAction(id, dispatch, currentPage, pageSize, search, category, data);
    // setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      findingsId: selectedIds == 0 ? lookalikeAppId : selectedIds,
      action: action,
    };
    fetchActiveVulnerabilityAction(id, dispatch, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    // setActionNote("");
    setSelectedIds([]);
  };


  let mapData = table;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `https://api.brandsek.com/api/cve-details/${id}/excel`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "IP Vulnerability Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const truncateDescription = (description) => {
    if (!description) return ""; // Return empty if description is undefined or null
    const words = description.split(" "); // Split the description into words
    if (words.length <= 30) return description; // If 100 words or less, return it as is
    return words.slice(0, 30).join(" ") + "..."; // Join first 100 words and add ellipsis
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Active Vulnerability Assessment</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {vendorName ? (
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{  }}
                    >
                      {vendorName}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="breadcrumb-item active" aria-current="page">
                    Active vulnerabilies
                  </li>
                </ol>
                <br />
                <span>
                  <b>Average Consolidated Severity : {avScore}</b>
                </span>
              </nav>
              {/* <p>Grade : {riskScore || "Calculating..."}</p> */}
            </div>
          </div>
        </div>

        {/* {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )} */}

        {/* <h6>
          <pre>{JSON.stringify(table_stats, null, 2)}</pre>
        </h6> */}
        {/* <h5>
          <pre>Total critical {TotalSeverities.totalCritic}</pre>
          <pre>Total High {TotalSeverities.totalHigh}</pre>
          <pre>Total Mid {TotalSeverities.totalMid}</pre>
          <pre>Total Low {TotalSeverities.totalLow}</pre>
          <pre>Total info {TotalSeverities.totalInfo}</pre>
        </h5> */}

        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("critical");
                      setSearch("critical");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={TotalSeverities?.totalCritic}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#DC2B2B",
                          centerColor: "#f7dad7",
                          bgrColor: "#DC2B2B",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      {/* <h4 className="mb-0">34.80%</h4> */}
                      <h4 className="mb-0">Critical</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("high");
                      setSearch("high");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={TotalSeverities?.totalHigh}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#EE4B2B",
                          centerColor: "#f7dad7",
                          bgrColor: "#EE4B2B",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">High</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("medium");
                      setSearch("medium");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={TotalSeverities?.totalMid}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#FF8C00",
                          centerColor: "#f7dad7",
                          bgrColor: "#FF8C00",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">Medium</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box mr-0">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("low");
                      setSearch("low");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={TotalSeverities?.totalLow}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#48B461",
                          centerColor: "#f7dad7",
                          bgrColor: "#48B461",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">Low</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="card w_card3 card-box mr-0">
                  <div
                    className="body w_summary"
                    style={{
                      backgroundColor: "",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      executeScroll("info");
                      setSearch("info");
                    }}
                  >
                    <div className="c_know mt-1 mr-3">
                      <Donut
                        className="knob"
                        diameter={45}
                        min={0}
                        max={100}
                        step={1}
                        value={TotalSeverities?.totalInfo}
                        theme={{
                          donutThickness: 2,
                          donutColor: "#1E90FF",
                          centerColor: "#f7dad7",
                          bgrColor: "#1E90FF",
                        }}
                      />
                    </div>
                    <div className="s_detail text-right">
                      <h4 className="mb-0">Info</h4>
                      <span>Vulnerabilities</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          {/* <div className="col-lg-12 col-md-12">
            <div className="card  white-box-dashboard">
              <div className="body">
                <div className="header">
                  <h6>Previous scan details</h6>
                </div>
                {scanDetails && (
                  <KnownsubscribersChart
                    dates={scanDetails?.dates}
                    data={scanDetails?.scanDetails}
                  />
                )}
              </div>
            </div>
          </div> */}
          <div className="col-lg-12 col-md-12">
            <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-table-tab nav-link ${
                    category === "all" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("all");
                    setCurrentPage(1);
                    setSearch("");
                    setSearch();
                    setSelectedIds([]);
                  }}
                >
                  Current
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "risk-accepted" ? "active" : ""
                  }`}
                  onClick={() => {
                    console.log("change to risk accepted")
                    setCategory("risk-accepted");
                    setCurrentPage(1);
                    setSearch("");
                    setRisk("");
                    setSelectedIds([]);
                  }}
                >
                  Risk Accepted
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "false-positive" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("false-positive");
                    setCurrentPage(1);
                    setRisk("");
                    setSearch("");
                    setSelectedIds([]);
                  }}
                >
                  False Positive
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link nav-table-tab ${
                    category === "remidiated" ? "active" : ""
                  }`}
                  onClick={() => {
                    setCategory("remidiated");
                    setCurrentPage(1);
                    setRisk("");
                    setSearch("");
                    setSelectedIds([]);
                  }}
                >
                  Remediated
                </a>
              </li>
            </ul>
            <div className="card">
              <div className="body">
                <div className="header" ref={myRef}>
                  <div className="row" style={{}}>
                    <div className="col-sm-6" style={{ marginTop: "10px" }}>
                      <h6>Vulnerability Details</h6>
                    </div>
                    <div className="col-sm-3 text-right">
                      <select
                        className="custom-select custom-select-sm form-control form-control-sm"
                        value={risk}
                        onChange={(e) => {
                          console.log("clicked option");
                          setCurrentPage(1);
                          setRisk(e.target.value);
                          setCategory("all");
                        }}
                      >
                        <option value="">All</option>
                        <option value="Critical">Critical</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                        <option value="Info">Info</option>
                      </select>
                    </div>
                    <div className="col-sm-1 d-flex justify-content-end">
                      {mapData?.length === 0 ? null : (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile();
                          }}
                          style={{ float: "right" }}
                          href="/add-report"
                          className="btn btn-primary mr-1"
                        >
                          <i className="fa fa-file-excel-o" style={{}}></i>
                        </a>
                      )}
                    </div>
                    <div className="col-sm-2">
                      {selectedIds.length > 1 && category === "all" && (
                        <Box sx={{ width: 200 }}>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedValue}
                              onChange={(event) => {
                                setAction(event.target.value);
                                snooze("", event.target.value);
                              }}
                            >
                              <MenuItem value="pleaseSelect">
                                Snooze Options
                              </MenuItem>
                              <MenuItem
                                value="riskAccept"
                                onClick={() => {
                                  addModal();
                                }}
                              >
                                Risk Accepted 2
                              </MenuItem>
                              <MenuItem
                                value="falsePositive"
                                onClick={() => {
                                  addModal();
                                }}
                              >
                                False Positive
                              </MenuItem>
                              <MenuItem value="markAsRemidiated"
                                >
                                Remediated
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      {selectedIds.length > 1 &&
                        (category == "false-positive" ||
                          category == "risk-accepted") && (
                          <button
                            className="btn btn-primary btn-sm mr-1 Unsnooze"
                            onClick={() => Unsnoozed("")}
                          >
                            Unsnooze
                          </button>
                        )}
                    </div>
                    <div className="col-lg-12">
                      <div className="table-responsive invoice_list mb-4">
                        <table className="table table-custom spacing5 mb-0">
                          <thead>
                            <tr>
                              {auth?.accessLevel !== "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedIds == 0 ? false : multiCheckTrue
                                    }
                                    onClick={() => {
                                      multicheck(currentTableData);
                                    }}
                                    className="checkUncheck"
                                  />
                                </th>
                              )}
                              <th style={{ width: "280px" }}>Title</th>
                              <th style={{ width: "60px" }}>Severity</th>
                              <th style={{ width: "120px" }}>CVE ID</th>
                              <th style={{ width: "480px" }}>Description</th>
                              <th style={{ width: "80px" }}>Age</th>
                              <th style={{ width: "100px" }}>Created Date</th>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <th style={{ width: "80px" }}>Action</th>
                                // <th style={{ width: "0px" }}></th>
                              )}
                              {/* <th style={{ width: "500px" }}>Solutions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData?.map((row) => {
                              return (
                                <tr key={row.id}>
                                  {auth?.accessLevel !== "Threat Analyst" && (category === "all" || category === "risk-accepted" || category === "false-positive") && (
                                    <td>
                                      <input
                                        onClick={() => singleClick(row?.id)}
                                        checked={selectedIds?.includes(
                                          row?.id
                                        )}
                                        type="checkbox"
                                        className="checkUncheck"
                                      />
                                    </td>
                                  )}
                                  <td style={{ whiteSpace: "unset" }}>
                                    <a href={`/active-vulnerabilities/${row?.id}`}>{row?.id}-{row?.title}</a>
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    <span
                                      className={
                                        row?.severity === "Info"
                                          ? "badge badge-info"
                                          : row?.severity === "Low"
                                          ? "badge badge-low"
                                          : row?.severity === "Medium"
                                          ? "badge badge-med"
                                          : row?.severity === "High"
                                          ? "badge badge-high"
                                          : row?.severity === "Critical"
                                          ? "badge badge-critical"
                                          : "N/A"
                                      }
                                    >
                                      {row?.severity}
                                    </span>
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {row?.vulnerability_ids?.length > 0 ? (
                                      row.vulnerability_ids.map((vul, index) => (
                                        <div key={index}>{vul.vulnerability_id}</div>
                                      ))
                                    ) : (
                                      <div>NA</div>
                                    )}
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {truncateDescription(row?.description)}
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {row?.age} Days 
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    {row?.date}
                                  </td>
                                  {/* {category === "all" && ( */}
                                  {category === "all" && auth?.accessLevel !== "Threat Analyst" && (
                                    <td>
                                      <Box sx={{ width: 200 }}>
                                        <FormControl fullWidth>
                                          {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedValue}
                                            onChange={(event) => {
                                              setSelectedIds([]);
                                              setAction(event.target.value);
                                              snooze(
                                                row?.id,
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <MenuItem value="pleaseSelect">
                                              Snooze Options
                                            </MenuItem>
                                            <MenuItem
                                              value="riskAccept"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row.id]);
                                              }}
                                            >
                                              Risk Accepted 3
                                            </MenuItem>
                                            <MenuItem
                                              value="falsePositive"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row.id]);
                                              }}
                                            >
                                              False Positive
                                            </MenuItem>
                                            <MenuItem value="markAsRemidiated"
                                              >
                                              Remediated
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </td>
                                  )}
                                  {auth?.accessLevel !== "Threat Analyst" && (category === "risk-accepted" ||
                                    category === "false-positive") && (
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm mr-1 Unsnooze"
                                        onClick={() => Unsnoozed(row?.id)}
                                      >
                                        Unsnooze
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="dataTables_paginate">
                        {totalItems > 0 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalItems}
                            pageSize={pageSize}
                            onPageChange={(currentPage) => {
                              setCurrentPage(currentPage);
                            }}
                          />
                        )}
                        {/* <button
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Prev
      </button>
      {[...Array(totalItems)].map((_, index) => (
        <button
          key={index}
          className={currentPage === index + 1 ? 'active' : ''}
          onClick={() => handlePageClick(index + 1)}
        >
          {index + 1}
        </button>
      ))}
      <button
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row clearfix"></div>
            </div>
          </div>
        </div>
        <div className="row clearfix"></div>
        
        <div
  className={`modal fade Form-Wizard-modal ${
    isModal === "Form Wizard modal" ? "d-block show" : ""
  }`}
>
  <div className="modal-dialog modal-lg" id="clientModal">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {action === "riskAccept"
            ? "Risk Accepted"
            : action === "markAsRemidiated"
            ? "Mark As Remediated"
            : action === "falsePositive"
            ? "Mark As False Positive"
            : "Unknown Action"}
        </h5>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setModal("");
            setActionNote("");
          }}
          className="close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="modal-body pricing_page modal_mob">
        <div className="">
          <div className="card mb-0">
            <div className="row clearfix">
              <label className="col-lg-12 col-md-12">
                Are you sure you want to proceed with this  for selected findings?
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setModal("");
            setActionNote("");
          }}
          className="btn btn-primary btn-brder-detail"
          style={{ marginRight: "10px" }}
        >
          <i className="fa-solid fa-xmark mr-1"></i> Cancel
        </button>
        <button
          type="button"
          style={{ fontWeight: "bold" }}
          onClick={submit}
          className="btn btn-primary"
        >
          <i className="fa-solid fa-floppy-disk mr-1"></i> Submit
        </button>
      </div>
    </div>
  </div>
</div>
        {/* <div
          className={`modal fade Form-Wizard-modal ${
            isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
          >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "falsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="">
                  <div className="card mb-0">
                    <div className="row clearfix">
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>

                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                  className="btn  btn-primary btn-brder-detail"
                  style={{ marginRight: "10px" }}
                >
                  <i class="fa-solid fa-xmark mr-1"></i> Cancel
                </button>
                <button
                  type="button"
                  style={{ fontWeight: "bold" }}
                  onClick={submit}
                  className="btn  btn-primary"
                >
                  <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                </button>
              </div>
            </div>
          </div>
        </div> */}
        </div>
    </>
  );
};

export default ActiveVulnerability;
