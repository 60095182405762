import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchPiiDetails,
  fetchPiiDetailsAction,
  fetchPiiDetailsTotal,
  fetchPiiDetailsGraph,
} from "../../actions/leakedCredentials";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $ from "jquery";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";

const StolenCredentials = () => {
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const dispatch = useDispatch();
  const [toggleArray, setToggleArray] = useState({});
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  const state = useSelector(
    (state) => state?.leakedCredential?.piidetails?.data?.data
  );
  const totalItem = useSelector(
    (state) => state?.leakedCredential?.piidetails?.data
  );
  const totalRecords = useSelector(
    (state) => state?.leakedCredential?.piiTotal?.data
  );
  let oldDnsDetails = useSelector(
    (state) => state.leakedCredential?.piiGraph?.data
  );

  let ipArray = [];
  if (oldDnsDetails?.scanDetails) {
    ipArray.push(oldDnsDetails?.scanDetails || 0);
  }

  useEffect(() => {
    fetchPiiDetails(id, dispatch, page, category, search);
  }, [id, dispatch, page, category, search]);

  useEffect(() => {
    fetchPiiDetailsTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchPiiDetailsGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(totalItem?.totalPersonalInformationLeakage);
  }, [totalItem?.totalPersonalInformationLeakage]);

  const auth = useSelector((state) => state?.auth?.user);

  // let response;
  if (state && loading) {
    const data = state?.reduce((result, credential) => {
      if (result[credential.email]) {
        result[credential.email].username.push(credential.username);
        result[credential.email].password.push(credential.password);
        result[credential.email].ip_address.push(credential.ip_address);
        result[credential.email].database_name.push(credential.database_name);
        result[credential.email].phone.push(credential.phone);
        result[credential.email].count = result[credential.email].count + 1;
      } else {
        credential.username = [credential.username];
        credential.password = [credential.password];
        credential.ip_address = [credential.ip_address];
        credential.database_name = [credential.database_name];
        credential.phone = [credential.phone];
        credential.count = 1;
        result[credential.email] = credential;
      }

      return result;
    }, {});
    let temp = {
      ...state,
      data: Object.keys(data || {}).map((email) => data[email]),
    };
    setResponse(temp?.data);
    setLoading(false);
  }

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        leakedPersonalInformationIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchPiiDetailsAction(id, dispatch, page, category, search, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedPersonalInformationIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchPiiDetailsAction(id, dispatch, page, category, search, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      leakedPersonalInformationIds:
        selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchPiiDetailsAction(id, dispatch, page, category, search, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  let mapData = state;

  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/pii-details/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Personal Information.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleIcon = (_id, index, value) => {
    let val = toggleArray;
    let id = _id + "_" + index;
    if (val[id]) {
      $("#" + _id + "_" + index + "_password").html("******");
      $("#" + _id + "_" + index + "_password_icon").attr("class", "fa fa-eye");
      // fa fa-eye-slash
      delete val[id];
      setToggleArray({ ...val });
    } else {
      $("#" + _id + "_" + index + "_password").html(value);
      $("#" + _id + "_" + index + "_password_icon").attr(
        "class",
        "fa fa-eye-slash"
      );
      val[id] = true;
      setToggleArray({ ...val });
    }
  };
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Personal Information Breached</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Personal Information
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {totalRecords?.individualRating}</b>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span>
              {mapData?.length === 0 ? null : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                  }}
                  href="/add-report"
                  style={{   fontWeight: "bold" }}
                  className="btn btn-primary mr-1"
                >
                  <i
                    className="fa fa-file-excel-o mr-1"
                    style={{   }}
                  ></i>{" "}
                  Download Details
                </a>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card card-box">
          <div className="d-flex justify-content-between">
              <i className="font-50 fa fa-database icon-dashboard"></i>
              <h4 className="font-44 total-h4">
              {totalRecords?.totalPersonalInformationLeakage || 0}
              </h4>
              </div>
              <label className="mb-0 text-right">Records Found</label>
              <hr className="border-red"></hr>

            <div className="body  pt-1" style={{   }}>
              {/* <div className="header">
                  <h2>Details</h2>
                </div> */}
               
              <div className="form-group list-with-number float-left">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Address{" "}
                  <span className="float-right">
                    {totalRecords?.address_count}
                  </span>
                </label>
              <span></span>
              </div>

              <div className="form-group list-with-number float-left">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Ip{" "}
                  <span className="float-right">
                    {totalRecords?.ip_address_count}
                  </span>
                </label>
                <span></span>
              </div>

              <div className="form-group list-with-number float-left">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Phone Number{" "}
                  <span className="float-right">
                    {totalRecords?.phone_count}
                  </span>
                </label>
                <span></span>
              </div>

              <div className="form-group list-with-number float-left">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  UserName{" "}
                  <span className="float-right">
                    {totalRecords?.username_count}
                  </span>
                </label>
                <span></span>
              </div>

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("multiple")}>
                  Users Breached Multiple Times{" "}
                  <span className="float-right">
                    {breachedPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-warning"
                    role="progressbar"
                    aria-valuenow={breachedPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: breachedPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("contact")}>
                  Contact Number/ address found{" "}
                  <span className="float-right">
                    {phonePasswordPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={phonePasswordPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: phonePasswordPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "440px" }}>
              <h6>Previous scan Details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={oldDnsDetails?.scanDetails}
                  color={["#DD3731"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${
              category === "all" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "risk-accepted" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "false-positive" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setSelectedIds([]);

              setSearch("");
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${
              category === "remidiated" ? "active" : ""
            }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
      <div className="card">
        <div className="body">
          <div className="row">
            <div className="col-sm-4">
              <div
                data-test="datatable-res-input"
                class="mdb-datatable-filter flex-row"
              >
                <input
                  class="form-control form-control-sm ml-0 my-1"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              {selectedIds.length > 1 && category === "all" && (
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      onChange={(event) => {
                        setAction(event.target.value);
                        snooze("", event.target.value);
                      }}
                    >
                      <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                      <MenuItem
                        value="riskAccepted"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        Risk Accepted
                      </MenuItem>
                      <MenuItem
                        value="markAsFalsePositive"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        False Positive
                      </MenuItem>
                      <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {selectedIds.length > 1 &&
                (category == "false-positive" ||
                  category == "risk-accepted") && (
                  <button
                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                    onClick={() => Unsnoozed("")}
                  >
                    Unsnooze
                  </button>
                )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                        <th>
                          <input
                            type="checkbox"
                            checked={selectedIds == 0 ? false : multiCheckTrue}
                            onClick={() => {
                              multicheck(currentTableData);
                            }}
                            className="checkUncheck"
                          />
                        </th>
                      )}
                      <th>Date</th>
                      {/* <th>Breached Count</th> */}
                      <th>Breached Database</th>
                      <th>Email Address</th>
                      {/* <th>Password</th> */}
                      <th>Username</th>
                      <th>Contact Number</th>
                      <th>IP Address</th>
                      <th>Residential Address</th>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                        <th style={{ width: "80" }}>Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      return (
                        <tr index={"piii_" + index}>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <input
                                onClick={() => singleClick(row?._id)}
                                checked={selectedIds?.includes(row?._id)}
                                type="checkbox"
                                className="checkUncheck"
                              />
                            </td>
                          )}
                          <td>
                            {" "}
                            {row?.updatedAt
                              ? moment(row?.createdAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : row?.createdAt
                              ? moment(row?.createdAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : "NA"}
                          </td>
                          {/* <td>
                            <span className="badge badge-danger ml-0 mr-0">
                              {row?.count}
                            </span>
                          </td> */}
                          <td style={{ whiteSpace: "unset", width: "100px" }}>
                            <span>
                              {row?.database_name}
                              <br />
                            </span>
                          </td>
                          <td style={{ whiteSpace: "unset", width: "100px" }}>
                            {row?.email || "NA"}
                          </td>
                          {/* <td>
                            <span id={`${row?._id}_${index}_password`}>
                              ******
                            </span>
                            <i
                              class="fa fa-eye"
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                fontSize: "16px",
                              }}
                              id={`${row?._id}_${index}_password_icon`}
                              onClick={() =>
                                toggleIcon(
                                  row?._id,
                                  index,
                                  row?.password || "NA"
                                )
                              }
                            ></i>
                            <br />
                          </td> */}
                          <td>
                            <span>
                              {row?.username || "NA"}
                              <br />
                            </span>
                          </td>
                          <td>
                            <span>
                              {row?.phone || "NA"}
                              <br />
                            </span>
                          </td>
                          <td>
                            <span>
                              {row?.ip_address || "NA"}
                              <br />
                            </span>
                          </td>
                          <td>{row?.address || "NA"}</td>
                          {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(row?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                            category === "false-positive") && (
                            <td>
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed(row?._id)}
                              >
                                Unsnooze
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="">
                <div className="card mb-0">
                  <div className="row clearfix">
                     
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                     
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                          setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StolenCredentials;
