import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class Piechart extends Component {
  constructor(props) {
    super(props);
    //console.log(props,'propsssssssss')
    this.state = {
      series: props.series,
      options: {
        chart: {
          width: 10,
          type: "pie",
        },
        plotOptions: {
          pie: {
            // donut: {
            size: "100%",
            // }
          },
        },
        colors: [
          "#ff6d6d", // Light Red
          "#c62828", // Dark Red
          "#ff8a8a", // Light Coral
          "#d32f2f", // Dark Coral
          "#e1f4e5", // Mint Green
          "#004d40", // Dark Teal
          "#00796b", // Dark Green
          "#c0d9ed", // Pale Blue
          "#003366", // Dark Blue
          "#a4c7e1", // Soft Blue
          "#002d72", // Deep Blue
          "#f1e1b0", // Pale Yellow
          "#f57f17", // Dark Yellow
          "#f5f7e5", // Light Cream
          "#fbc02d", // Mustard Yellow
          "#ffe3f2", // Light Pink
          "#d81b60", // Dark Pink
          "#fadadd", // Light Blush
          "#c2185b", // Deep Pink
          "#e5d1ed", // Light Purple
          "#4a148c", // Dark Purple
          "#d1c4e9", // Soft Lavender
          "#6a1b9a", // Deep Lavender
          "#f2f4f8", // Light Grey
          "#424242", // Dark Grey
          "#e0e0e0", // Pale Grey
          "#b6e2d3", // Light Teal
          "#616161", // Medium Grey
          "#f5e4d2", // Light Beige
          "#3e2723", // Dark Brown
          "#d7ccc8", // Pale Brown
          "#5d4037", // Medium Brown
          "#cce5ff", // Light Blue
          "#003b6f", // Dark Blue
          "#d5f5e3", // Pale Mint
          "#004d40", // Dark Mint
          "#f6e6e6", // Soft Rose
          "#b71c1c", // Dark Rose
          "#d0f4f4", // Soft Aqua
          "#004d40", // Dark Aqua
          "#f2e2d2", // Light Taupe
          "#3e2723", // Dark Taupe
          
                // Additional Colors
                "#ffb3b3", // Light Blush
                "#b71c1c", // Dark Red
                "#c4b7e7", // Lavender
                "#8e44ad", // Dark Purple
                "#e0f7fa", // Light Cyan
                "#00695c", // Dark Cyan
                "#c5e1a5", // Light Olive
                "#3c8031", // Dark Olive
                "#d4e157", // Light Lime
                "#9e9d24", // Dark Lime
                "#b2dfdb", // Light Mint
                "#004d40", // Dark Mint
                "#ffeb3b", // Bright Yellow
                "#f57f17", // Dark Yellow
                "#ffb74d", // Light Orange
                "#e65100", // Dark Orange
                "#f48fb1", // Light Pink
                "#c2185b", // Dark Pink
                "#b3e5fc", // Light Sky Blue
                "#01579b", // Dark Sky Blue
                "#cfd8dc", // Light Slate
                "#37474f", // Dark Slate
             
                // New Colors
                "#e0e0e0", // Light Gray
                "#212121", // Dark Gray
                "#f4c542", // Light Gold
                "#f57c00", // Dark Orange
                "#a5d6a7", // Light Green
                "#004d40", // Deep Teal
                "#b39ddb", // Light Purple
                "#7e57c2", // Dark Purple
                "#d1c4e9", // Light Lavender
                "#5e35b1", // Dark Lavender
                "#d1c4e9", // Lavender
                "#e57373", // Soft Red
                "#b2dfdb", // Mint
                "#009688", // Dark Teal
                "#b9fbc0", // Light Mint Green
                "#004d40", // Dark Mint Green
                "#ffe082", // Light Amber
                "#f57f17", // Dark Amber
                "#c5cae9", // Light Periwinkle
                "#303f9f", // Dark Periwinkle
                "#dce775"  // Light Olive Green
              ],
          
        labels: props.label,
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          markers: {
            radius: 0,
          },
        },
      },
    };
  }
  render() {
    return (
      <ReactApexChart
        options={this.state.options}
        series={this.state.series}
        type="pie"
        height={430}
      />
    );
  }
}

export default Piechart;
