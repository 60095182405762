import Login from './Authentication/login';
import ProfileUpdate from './Authentication/profileUpdate';
import EmailUpdate from './Authentication/email-verify';

import Signup from './Authentication/signup';
import ForgotPassword from './Authentication/forgotpassword';
import NotFound from './Authentication/404';
import Maintenance from './Authentication/maintenance';
import Dashboard from './Dashboard/dashboard';
import Summary from './Dashboard/Summary';
import ASMDashboard from './Dashboard/ASMDashboard';
import DataDashboard from './Dashboard/DataDashboard';
import BrandDashboard from './Dashboard/BrandDashboard';
import VendorSummary from './Dashboard/VendorSummary';
import BreachSummary from './Dashboard/BreachSummary';
import NewSummary from './Dashboard/newsummary';
import Dashboard2 from './Dashboard/dashboard2';
import Dashboard3 from './Dashboard/dashboard3';
import Dashboard4 from './Dashboard/dashboard4';
import Dashboard5 from './Dashboard/dashboard5';
import Dashboard6 from './Dashboard/dashboard6';
import Dashboard7 from './Dashboard/dashboard7';
import Dashboard8 from './Dashboard/dashboard8';
import Dashboard9 from './Dashboard/dashboard9';
import Dashboard10 from './Dashboard/dashboard10';
import Dashboard11 from './Dashboard/dashboard11';
import Dashboard12 from './Dashboard/dashboard12';
import AssetsDiscovery from './SecurityRisk/assetsdiscovery';
import KnownVulnerabilities from './SecurityRisk/KnownVulnerabilities';
import ActiveVulnerabilities from './SecurityRisk/ActiveVulnerabilities';
import AV_VulnerabilityDetail from './SecurityRisk/AV_VulnerabilityDetail';
import InfrastructureVA from './VulnerabilityAssessment/InfrastructureVA';
import ApplicationVA from './VulnerabilityAssessment/ApplicationVA';
import ScanPage from './VulnerabilityAssessment/ScanPage';
import ExpiredSSLCertificates from './SecurityRisk/ExpiredSSLCertificates';
import VulnerableCertificates from './SecurityRisk/VulnerableCertificates';
import WebTechnology from './SecurityRisk/WebTechnology';

import SecurityHeader from './SecurityRisk/securityHeader';
import Domains from './SecurityRisk/SubDomain';
import SPFDMARC from './SecurityRisk/SPFDmarc';
import BrandMention from './SecurityRisk/BrandMention'
import SubDomainDiscovery from './SecurityRisk/SubDomainDiscovery';
import DanglingSubDomains from './SecurityRisk/DanglingSubDomains';

import LookAlikeDomains from './SecurityRisk/LookAlikeDomain';
import SocialMediaProfile from './SecurityRisk/SocialMediaProfile';
import WebDefacement from './SecurityRisk/WebDefacement';

import MobileApp from './SecurityRisk/MobileApp';
import StolenCredentials from './BreachRisk/StolenCredentials';
import PII from './BreachRisk/pii';
import PhishingPage from './SecurityRisk/phishingPage';
import PasteBin from './BreachRisk/PasteBin';
import MalwareCredentials from './BreachRisk/MalwareCredentials';
import LeakedSessions from './BreachRisk/LeakedSessions';
import Telegrammonitoring from './BreachRisk/Telegrammonitoring';
import PostmanMonitoring from './BreachRisk/PostmanMonitoring'


import SourceCodeLeakage from './BreachRisk/SourceCodeLeakage';
import IPBlacklist from './BreachRisk/IPBlacklist';
import FeedInformation from './information/FeedInformation';
import ShowInformation from './information/ShowInformation';
import CloudBuckets from './BreachRisk/CloudBuckets';
import CloudAccounts from './cspm/CloudAccounts';
import CloudAccountDetails from './cspm/CloudAccountDetails';
import ScanResults from './cspm/ScanResults';
import Integrations from './integrations/Integrations';

import FormAdvanced from './Form/formAdvanced';
import FormBasic from './Form/formsBasic';
import FormCropping from './Form/formsCropping';
import FormDragdropupload from './Form/formDragdropupload';
import FormsEditors from './Form/formsEditors';
import FormSummernote from './Form/formSummernote';
import FormValidation from './Form/formValidation';
import FormWizard from './Form/formWizard';
import ListView from './Contacts/ListView';
import GridView from './Contacts/GridView';
import Inbox from './Email/Inbox';
import Compose from './Email/Compose';
import Details from './Email/Details';
import Chat from './Messenger/chat';
import ProjectList from './Projects/ProjectList';
import InviteUser from './Projects/InviteUser';
import TicketList from './Projects/TicketList';
import TicketDetails from './Projects/TicketDetails';
import Taskboard from './Projects/Taskboard';
import TodoList from './Projects/TodoList';
import Clients from './Projects/Clients';
import TrialRequest from './Projects/trialRequest';
import Vendors from './Projects/Vendors';

import Icons from './UI/Icons';
import SimpleLine from './UI/SimpleLine';
import Themify from './UI/Themify';
import Bootstrap from './Components/Bootstrap';
import Typography from './Components/Typography';
import RangeSliders from './Components/RangeSliders';
import Tabs from './Components/Tabs';
import ProgressBars from './Components/ProgressBars';
import Notifications from './Components/Notifications';
import Modals from './Components/Modals';
import Calendar from './Calendar/Calendar';
import ApexChart from './Chart/apexChart';
import jVector from './Map/jVector';
import PageSocial from './Pages/pageSocial';
import PageNews from './Pages/pageNews';
import PageBlogPost from './Pages/pageBlogPost';
import PageBlog from './Pages/pageBlog';
import pageBlank from './Pages/pageBlank';
import pageProfile from './Pages/pageProfile';
import CompanyProfile from './Pages/CompanyProfile';
import Settings from './Pages/Settings';
import Subscription from './Pages/Subscription';
import Configuration from './Pages/Configuration';
import pageUserList from './Pages/pageUserList';
import PageTestimonials from './Pages/pageTestimonials';
import PageInvoiceDetails from './Pages/pageInvoiceDetails';
import PageInvoice from './Pages/pageInvoice';
import PageTimeline from './Pages/pageTimeline';
import PageSearchResult from './Pages/pageSearchResult';
import PageGallery from './Pages/pageGallery';
import PagePricing from './Pages/pagePricing';
import PageComingSoon from './Pages/pageComingSoon';
import Widgets from './Widgets/Widgets';
import TableColor from './Table/tableColor';
import TableDragger from './Table/tableDragger';
import TableEditable from './Table/tableEditable';
import TableFilter from './Table/tableFilter';
import TableJqueryDatatable from './Table/tableJqueryDatatable';
import TableNormal from './Table/tableNormal';
import Colors from './Components/Colors';
import Buttons from './Components/Buttons';
import Dialogs from './Components/Dialogs';
import ListGroup from './Components/ListGroup';
import MediaObject from './Components/MediaObject';
import Nestable from './Components/Nestable';
import EmailVerfiy from './Authentication/email-verify';
import CVEinformation from './Projects/CVEInformation';
import CVEinformationEdit from './Projects/CVEInformationEdit';
import vendorProduct from './Projects/vendorandProduct';
import PostManMonitoringDetail from './BreachRisk/PostMonitoringDetail';
import DarkWeb from './BreachRisk/DarkWeb';
import cveMonitoring from './Projects/CveMonitoring';


const Routes = [
    {
        path: "/ui-nestable",
        name: 'Nestable',
        exact: true,
        pageTitle: "Nestable",
        component: Nestable
    },
    {
        path: "/ui-media-object",
        name: 'MediaObject',
        exact: true,
        pageTitle: "MediaObject",
        component: MediaObject
    },
    {
        path: "/ui-list-groups",
        name: 'ListGroup',
        exact: true,
        pageTitle: "ListGroup",
        component: ListGroup
    },
    {
        path: "/ui-dialogs",
        name: 'Dialogs',
        exact: true,
        pageTitle: "Dialogs",
        component: Dialogs
    },
    {
        path: "/ui-buttons",
        name: 'Buttons',
        exact: true,
        pageTitle: "Buttons",
        component: Buttons
    },
    {
        path: "/ui-colors",
        name: 'Colors',
        exact: true,
        pageTitle: "Colors",
        component: Colors
    },
    {
        path: "/table/table-normal",
        name: 'tablenormal',
        exact: true,
        pageTitle: "TableNormal",
        component: TableNormal
    },
    {
        path: "/table/table-jquery-datatable",
        name: 'tablejquerydatatable',
        exact: true,
        pageTitle: "TableJqueryDatatable",
        component: TableJqueryDatatable
    },
    {
        path: "/table/table-filter",
        name: 'tablefilter',
        exact: true,
        pageTitle: "TableFilter",
        component: TableFilter
    },
    {
        path: "/table/table-editable",
        name: 'tableeditable',
        exact: true,
        pageTitle: "TableEditable",
        component: TableEditable
    },
    {
        path: "/table/table-dragger",
        name: 'tabledragger',
        exact: true,
        pageTitle: "TableDragger",
        component: TableDragger
    },
    {
        path: "/table/table-color",
        name: 'tablecolor',
        exact: true,
        pageTitle: "TableColor",
        component: TableColor
    },
    {
        path: "/page-coming-soon",
        name: 'Page Coming Soon',
        exact: true,
        pageTitle: "Page Coming Soon",
        component: PageComingSoon
    },
    {
        path: "/widgets",
        name: 'Widgets',
        exact: true,
        pageTitle: "Widgets",
        component: Widgets
    },
    {
        path: "/page-pricing",
        name: 'Page Pricing',
        exact: true,
        pageTitle: "Page Gallery",
        component: PagePricing
    },
    {
        path: "/dashboard12",
        name: 'dashboard12',
        exact: true,
        pageTitle: "Dashboard12",
        component: Dashboard12
    },
    {
        path: "/assets",
        name: 'assetsDiscovery',
        exact: true,
        pageTitle: "Assets Discovered",
        component: AssetsDiscovery
    },
    {
        path: "/assets/vendor/:orgId",
        name: 'assetsDiscovery',
        exact: true,
        pageTitle: "Assets Discovered",
        component: AssetsDiscovery
    },
    {
        path: "/active-vulnerabilities",
        name: 'ActiveVulnerabilities',
        exact: true,
        pageTitle: "Active Vulnerabilities",
        component: ActiveVulnerabilities
    },
    {
        path: "/active-vulnerabilities/:vulnerabilityId",
        name: 'AV_VulnerabilityDetail',
        exact: true,
        pageTitle: "Active Vulnerability Detail",
        component: AV_VulnerabilityDetail
    },
    {
        path: "/known-vulnerabilities",
        name: 'knownVulnerabilities',
        exact: true,
        pageTitle: "Known Vulnerabilities",
        component: KnownVulnerabilities
    },
    {
        path: "/known-vulnerabilities/vendor/:orgId",
        name: 'knownVulnerabilities',
        exact: true,
        pageTitle: "Known Vulnerabilities",
        component: KnownVulnerabilities
    },
    {
        path: "/infra-va",
        name: 'infraVA',
        exact: true,
        pageTitle: "Infrastrucutre",
        component: InfrastructureVA
    },
    {
        path: "/infra-va/vendor/:orgId",
        name: 'infraVA',
        exact: true,
        pageTitle: "Infrastrucutre",
        component: InfrastructureVA
    },
    {
        path: "/app-va",
        name: 'applicationVA',
        exact: true,
        pageTitle: "Application",
        component: ApplicationVA
    },
    {
        path: "/scan",
        name: 'scanPage',
        exact: true,
        pageTitle: "Scan",
        component: ScanPage
    },
    {
        path: "/ssl-certificates",
        name: 'sslCertificates',
        exact: true,
        pageTitle: "SSL Certificates",
        component: ExpiredSSLCertificates
    },
    {
        path: "/ssl-certificates/vendor/:orgId",
        name: 'sslCertificates',
        exact: true,
        pageTitle: "SSL Certificates",
        component: ExpiredSSLCertificates
    },
    {
        path: "/vulnerable-certificates",
        name: 'vulnerableCertificates',
        exact: true,
        pageTitle: "Vulnerable Certificates",
        component: VulnerableCertificates
    },
    {
        path: "/vulnerable-certificates/vendor/:orgId",
        name: 'vulnerableCertificates',
        exact: true,
        pageTitle: "Vulnerable Certificates",
        component: VulnerableCertificates
    },
    {
        path: "/web-technology",
        name: 'webTechnology',
        exact: true,
        pageTitle: "Web Technology",
        component: WebTechnology
    },
    {
        path: "/web-technology/vendor/:orgId",
        name: 'webTechnology',
        exact: true,
        pageTitle: "Web Technology",
        component: WebTechnology
    },
    {
        path: "/brand-mentions",
        name: 'brandMentions',
        exact: true,
        pageTitle: "Brand Mention",
        component: BrandMention
    },
    {
        path: "/brand-mentions/vendor/:orgId",
        name: 'brandMentions',
        exact: true,
        pageTitle: "Brand Mention",
        component: BrandMention
    },
    {
        path: "/security-header",
        name: 'securityHeader',
        exact: true,
        pageTitle: "Security Header",
        component: SecurityHeader
    },
    {
        path: "/domains",
        name: 'domains',
        exact: true,
        pageTitle: "Domains",
        component: Domains
    },
    {
        path: "/domains/vendor/:orgId",
        name: 'domains',
        exact: true,
        pageTitle: "Domains",
        component: Domains
    },
    {
        path: "/spf-dmarc",
        name: 'spf-dmarc',
        exact: true,
        pageTitle: "SPFDmarch",
        component: SPFDMARC
    },
    {
        path: "/spf-dmarc/vendor/:orgId",
        name: 'spf-dmarc',
        exact: true,
        pageTitle: "SPFDmarch",
        component: SPFDMARC
    },
    {
        path: "/subdomains",
        name: 'subDomains',
        exact: true,
        pageTitle: "Subdomains",
        component: SubDomainDiscovery
    },
    {
        path: "/subdomains/vendor/:orgId",
        name: 'subDomains',
        exact: true,
        pageTitle: "Subdomains",
        component: SubDomainDiscovery
    },
    {
        path: "/danglingdomain",
        name: 'danglingDomain',
        exact: true,
        pageTitle: "Dangling Subdomains",
        component: DanglingSubDomains
    },
    {
        path: "/danglingdomain/vendor/:orgId",
        name: 'danglingDomain',
        exact: true,
        pageTitle: "Dangling Subdomains",
        component: DanglingSubDomains
    },
    {
        path: "/look-alike",
        name: 'lookAlikeDomains',
        exact: true,
        pageTitle: "Look-alike Domains",
        component: LookAlikeDomains
    },
    {
        path: "/look-alike/vendor/:orgId",
        name: 'lookAlikeDomains',
        exact: true,
        pageTitle: "Look-alike Domains",
        component: LookAlikeDomains
    },
    {
        path: "/social-media",
        name: 'socialMedia',
        exact: true,
        pageTitle: "Social Media Profile",
        component: SocialMediaProfile
    },
    {
        path: "/social-media/vendor/:orgId",
        name: 'socialMedia',
        exact: true,
        pageTitle: "Social Media Profile",
        component: SocialMediaProfile
    },
    {
        path: "/web-defacement",
        name: 'webDefacement',
        exact: true,
        pageTitle: "Web Defacement",
        component: WebDefacement
    },
    {
        path: "/web-defacement/vendor/:orgId",
        name: 'webDefacement',
        exact: true,
        pageTitle: "Web Defacement",
        component: WebDefacement
    },
    {
        path: "/mobile-apps",
        name: 'mobileApp',
        exact: true,
        pageTitle: "Mobile Applications",
        component: MobileApp
    },
    {
        path: "/mobile-apps/vendor/:orgId",
        name: 'mobileApp',
        exact: true,
        pageTitle: "Mobile Applications",
        component: MobileApp
    },
    {
        path: "/stolen-credentials",
        name: 'stolenCredentials',
        exact: true,
        pageTitle: "Stolen Credentials",
        component: StolenCredentials
    },
    {
        path: "/stolen-credentials/vendor/:orgId",
        name: 'stolenCredentials',
        exact: true,
        pageTitle: "Stolen Credentials",
        component: StolenCredentials
    },
    {
        path: "/telegram-monitoring",
        name: 'telegram-monitoring',
        exact: true,
        pageTitle: "Telegram Monitoring",
        component: Telegrammonitoring
    },
    {
        path: "/telegram-monitoring/vendor/:orgId",
        name: 'telegram-monitoring',
        exact: true,
        pageTitle: "Telegram Monitoring",
        component: Telegrammonitoring
    },
    {
        path: "/cve",
        name: 'CVE Information',
        exact: true,
        pageTitle: "CVE Information",
        component: CVEinformation
    },
    {
        path: "/cve/vendor/:orgId",
        name: 'CVE Information',
        exact: true,
        pageTitle: "CVE Information",
        component: CVEinformation
    },
    {
        path: "/product-vendor/:data",
        name: 'CVE Information',
        pageTitle: "CVE Information",
        component: CVEinformation
    },
    {
        path: "/cve/:cveId",
        name: 'CVE Information',
        exact: true,
        pageTitle: "CVE Information Edit",
        component: CVEinformationEdit
    },
    {
        path: "/vendor-product/vendor/:orgId",
        name: 'Vendor & Product',
        exact: true,
        pageTitle: "Vendor & Product",
        component: vendorProduct
    },
    {
        path: "/vendor-product",
        name: 'Vendor & Product',
        exact: true,
        pageTitle: "Vendor & Product",
        component: vendorProduct
    },
    {
        path: "/postman-monitoring",
        name: 'postman-monitoring',
        exact: true,
        pageTitle: "PostMan Monitoring",
        component:PostmanMonitoring
    },
    {
        path: "/postman-monitoring/vendor/:orgId",
        name: 'postman-monitoring',
        exact: true,
        pageTitle: "PostMan Monitoring",
        component:PostmanMonitoring
    },
    {
        path: "/postman-monitoring/:routeId",
        name: 'postman-monitoring-detail',
        exact: true,
        pageTitle: "PostMan Monitoring",
        component:PostManMonitoringDetail
    },
    {
        path: "/pii",
        name: 'PII',
        exact: true,
        pageTitle: "PII",
        component: PII
    },
    {
        path: "/pii/vendor/:orgId",
        name: 'PII',
        exact: true,
        pageTitle: "PII",
        component: PII
    },
    {
        path: "/phishing",
        name: 'phishingPage',
        exact: true,
        pageTitle: "Phishing Page",
        component: PhishingPage
    },
    {
        path: "/phishing/vendor/:orgId",
        name: 'phishingPage',
        exact: true,
        pageTitle: "Phishing Page",
        component: PhishingPage
    },
    {
        path: "/pastebin-search",
        name: 'pastebinSearch',
        exact: true,
        pageTitle: "Pastebin Search",
        component: PasteBin
    },
    {
        path: "/pastebin-search/vendor/:orgId",
        name: 'pastebinSearch',
        exact: true,
        pageTitle: "Pastebin Search",
        component: PasteBin
    },
    {
        path: "/dark-web",
        name: 'darkweb',
        exact: true,
        pageTitle: "Dark Web",
        component: DarkWeb
    },
    {
        path: "/dark-web/vendor/:orgId",
        name: 'darkweb',
        exact: true,
        pageTitle: "Dark Web",
        component: DarkWeb
    },
    {
        path: "/malware-credentials",
        name: 'malwareCredentials',
        exact: true,
        pageTitle: "Malware Credentials",
        component: MalwareCredentials
    },
    {
        path: "/malware-credentials/vendor/:orgId",
        name: 'malwareCredentials',
        exact: true,
        pageTitle: "Malware Credentials",
        component: MalwareCredentials
    },
    {
        path: "/leaked-sessions",
        name: 'leakedSessions',
        exact: true,
        pageTitle: "Leaked Sessions",
        component: LeakedSessions
    },
    {
        path: "/leaked-sessions/vendor/:orgId",
        name: 'leakedSessions',
        exact: true,
        pageTitle: "Leaked Sessions",
        component: LeakedSessions
    },
    
    {
        path: "/source-code",
        name: 'sourceCode',
        exact: true,
        pageTitle: "Source Code Leakage",
        component: SourceCodeLeakage
    },
    {
        path: "/source-code/vendor/:orgId",
        name: 'sourceCode',
        exact: true,
        pageTitle: "Source Code Leakage",
        component: SourceCodeLeakage
    },
    {
        path: "/ip-blacklist",
        name: 'ipBlacklist',
        exact: true,
        pageTitle: "IP Blacklist",
        component: IPBlacklist
    },
    {
        path: "/ip-blacklist/vendor/:orgId",
        name: 'ipBlacklist',
        exact: true,
        pageTitle: "IP Blacklist",
        component: IPBlacklist
    },
    {
        path: "/feed-information/:orgId",
        name: 'feedInformation',
        exact: true,
        pageTitle: "Feed Information",
        component: FeedInformation
    },
    {
        path: "/show-details/:id",
        name: 'show',
        exact: true,
        pageTitle: "Show Details",
        component: ShowInformation
    },
    {
        path: "/cloud-bucket/vendor/:orgId",
        name: 'cloudBucket',
        exact: true,
        pageTitle: "Cloud Buckets",
        component: CloudBuckets
    },
    {
        path: "/cloud-bucket",
        name: 'cloudBucket',
        exact: true,
        pageTitle: "Cloud Buckets",
        component: CloudBuckets
    },
    {
        path: "/cloud-account",
        name: 'cloudAccount',
        exact: true,
        pageTitle: "Cloud Security",
        component: CloudAccounts
    },
    {
        path: "/account-details/:accountId",
        name: 'accountDetails',
        exact: true,
        pageTitle: "Account Details",
        component: CloudAccountDetails
    },
    {
        path: "/scan-results/:scanId",
        name: 'scanResults',
        exact: true,
        pageTitle: "Scan Results",
        component: ScanResults
    },
    {
        path: "/integrations",
        name: 'integrations',
        exact: true,
        pageTitle: "Integrations",
        component: Integrations
    },
    {
        path: "/integrations/vendor/:orgId",
        name: 'integrations',
        exact: true,
        pageTitle: "Integrations",
        component: Integrations
    },
    {
        path: "/summary",
        name: 'summary',
        exact: true,
        pageTitle: "Summary",
        component: Summary
    },
    {
        path: "/asm-details",
        name: 'asm',
        exact: true,
        pageTitle: "ASMDashboard",
        component: ASMDashboard
    },
    {
        path: "/asm-details/vendor/:orgId",
        name: 'asm',
        exact: true,
        pageTitle: "ASMDashboard",
        component: ASMDashboard
    },
    {
        path: "/data-details",
        name: 'dataDetails',
        exact: true,
        pageTitle: "DataDashboard",
        component: DataDashboard
    },
    {
        path: "/data-details/vendor/:orgId",
        name: 'dataDetails',
        exact: true,
        pageTitle: "DataDashboard",
        component: DataDashboard
    },
    {
        path: "/brand-details",
        name: 'brandDetails',
        exact: true,
        pageTitle: "BrandDashboard",
        component: BrandDashboard
    },
    {
        path: "/brand-details/vendor/:orgId",
        name: 'brandDetails',
        exact: true,
        pageTitle: "BrandDashboard",
        component: BrandDashboard
    },
    {
        path: "/new-summary",
        name: 'newSummary',
        exact: true,
        pageTitle: "New Summary",
        component: NewSummary
    },
    {
        path: "/vendorDetails/:orgId",
        name: 'summary',
        exact: true,
        pageTitle: "Summary",
        component: Summary
    },
    {
        path: "/vendor-summary",
        name: 'vendorSummary',
        exact: true,
        pageTitle: "VendorSummary",
        component: VendorSummary
    },
    {
        path: "/vendor-summary/:orgId",
        name: 'vendorSummary',
        exact: true,
        pageTitle: "VendorSummary",
        component: VendorSummary
    },
    {
        path: "/breach-summary",
        name: 'breachSummary',
        exact: true,
        pageTitle: "Breach Summary",
        component: BreachSummary
    },
    {
        path: "/breach-summary/vendor/:orgId",
        name: 'breachSummary',
        exact: true,
        pageTitle: "Breach Summary",
        component: BreachSummary
    },
    
    

    {
        path: "/task-board",
        name: 'Taskboard',
        exact: true,
        pageTitle: "Taskboard",
        component: Taskboard
    },
    {
        path: "/notifications",
        name: 'Notifications',
        exact: true,
        pageTitle: "Notifications",
        component: Notifications
    },
    {
        path: "/tabs",
        name: 'Tabs',
        exact: true,
        pageTitle: "Tabs",
        component: Tabs
    },
    {
        path: "/progressbars",
        name: 'ProgressBars',
        exact: true,
        pageTitle: "ProgressBars",
        component: ProgressBars
    },
    {
        path: "/modals",
        name: 'Modals',
        exact: true,
        pageTitle: "Modals",
        component: Modals
    },
    {
        path: "/ui-range-sliders",
        name: 'RangeSliders',
        exact: true,
        pageTitle: "RangeSliders",
        component: RangeSliders
    },
    {
        path: "/page-gallery",
        name: 'Page Gallery',
        exact: true,
        pageTitle: "Page Gallery",
        component: PageGallery
    },
    {
        path: "/page-search-results",
        name: 'Page Search Results',
        exact: true,
        pageTitle: "Page Search Results",
        component: PageSearchResult
    },
    {
        path: "/page-timeline",
        name: 'Page Timeline',
        exact: true,
        pageTitle: "Page Timeline",
        component: PageTimeline
    },
    {
        path: "/page-invoices",
        name: 'Page Invoice',
        exact: true,
        pageTitle: "Page Invoice",
        component: PageInvoice
    },
    {
        path: "/page-invoices-details",
        name: 'Page Invoice Details',
        exact: true,
        pageTitle: "Page Invoice Details",
        component: PageInvoiceDetails
    },
    {
        path: "/page-testimonials",
        name: 'Page Testimonials',
        exact: true,
        pageTitle: "Page Testimonials",
        component: PageTestimonials
    },
    {
        path: "/page-user-list",
        name: 'Page User List',
        exact: true,
        pageTitle: "Page User List",
        component: pageUserList
    },
    {
        path: "/profile",
        name: 'Page Profile',
        exact: true,
        pageTitle: "Page Profile",
        component: pageProfile
    },
    {
        path: "/profile/vendor/:orgId",
        name: 'Page Profile',
        exact: true,
        pageTitle: "Page Profile",
        component: pageProfile
    },
    {
        path: "/company-profile",
        name: 'Company Profile',
        exact: true,
        pageTitle: "Company Profile",
        component: CompanyProfile
    },
    {
        path: "/settings",
        name: 'Settings',
        exact: true,
        pageTitle: "Settings",
        component: Settings
    },
    {
        path: "/configuration",
        name: 'Configuration',
        exact: true,
        pageTitle: "Configuration",
        component: Configuration
    },
    {
        path: "/subscription",
        name: 'Subscriptions',
        exact: true,
        pageTitle: "Subscriptions",
        component: Subscription
    },
    {
        path: "/page-blank",
        name: 'Page Blank',
        exact: true,
        pageTitle: "Page Blank",
        component: pageBlank
    },
    {
        path: "/page-blog-post",
        name: 'Page Blog post',
        exact: true,
        pageTitle: "Page Blog Post",
        component: PageBlogPost
    },
    {
        path: "/page-blog",
        name: 'Page Blog',
        exact: true,
        pageTitle: "Page Blog",
        component: PageBlog
    },
    {
        path: "/clients",
        name: 'Clients',
        exact: true,
        pageTitle: "Clients",
        component: Clients
    },
    {
        path: "/clients/vendor/:orgId",
        name: 'Clients',
        exact: true,
        pageTitle: "Clients",
        component: Clients
    },
    {
        path: "/trial-request",
        name: 'TrialRequest',
        exact: true,
        pageTitle: "TrialRequest",
        component: TrialRequest
    },
    {
        path: "/trial-request/vendor/:orgId",
        name: 'TrialRequest',
        exact: true,
        pageTitle: "TrialRequest",
        component: TrialRequest
    },
    {
        path: "/vendor",
        name: 'Vendors',
        exact: true,
        pageTitle: "Vendors",
        component: Vendors
    },
    {
        path: "/vendor/:orgId",
        name: 'Vendors',
        exact: true,
        pageTitle: "Vendors",
        component: Vendors
    },
    {
        path: "/cve-monitoring/:orgId",
        name: 'cve-monitoring',
        exact: true,
        pageTitle: "Cve Monitoring",
        component: cveMonitoring
    },
    {
        path: "/cve-monitoring",
        name: 'cve-monitoring',
        exact: true,
        pageTitle: "Cve Monitoring",
        component: cveMonitoring
    },
    {
        path: "/app-todo",
        name: 'TodoList',
        exact: true,
        pageTitle: "TodoList",
        component: TodoList
    },
    {
        path: "/page-news",
        name: 'Page News',
        exact: true,
        pageTitle: "Page News",
        component: PageNews
    },
    {
        path: "/page-social",
        name: 'Page Social',
        exact: true,
        pageTitle: "Page Social",
        component: PageSocial
    },
    {
        path: "/app-jVector-map",
        name: 'JVector',
        exact: true,
        pageTitle: "JVector",
        component: jVector
    },
    {
        path: "/chart/chart-apex",
        name: 'ApexChart',
        exact: true,
        pageTitle: "ApexChart",
        component: ApexChart
    },
    {
        path: "/app-calendar",
        name: 'Calendar',
        exact: true,
        pageTitle: "Calendar",
        component: Calendar
    },
    {
        path: "/ui-typography",
        name: 'Typography',
        exact: true,
        pageTitle: "Typography",
        component: Typography
    },
    {
        path: "/ui-bootstrap",
        name: 'Bootstrap',
        exact: true,
        pageTitle: "Bootstrap",
        component: Bootstrap
    },
    {
        path: "/ui-icons-themify",
        name: 'Themify',
        exact: true,
        pageTitle: "Themify",
        component: Themify
    },
    {
        path: "/ui-icons-line",
        name: 'simpleLine',
        exact: true,
        pageTitle: "SimpleLine",
        component: SimpleLine
    },
    {
        path: "/ui-icons",
        name: 'icons',
        exact: true,
        pageTitle: "Icons",
        component: Icons
    },
    {
        path: "/app-ticket-details",
        name: 'ticketDetails',
        exact: true,
        pageTitle: "TicketDetails",
        component: TicketDetails
    },
    {
        path: "/app-ticket",
        name: 'ticketList',
        exact: true,
        pageTitle: "TicketList",
        component: TicketList
    },
    {
        path: "/app-project-list",
        name: 'projectList',
        exact: true,
        pageTitle: "ProjectList",
        component: ProjectList
    },
    {
        path: "/invite-user",
        name: 'inviteUser',
        exact: true,
        pageTitle: "Invite User",
        component: InviteUser
    },
    {
        path: "/invite-user/vendor/:orgId",
        name: 'inviteUser',
        exact: true,
        pageTitle: "Invite User",
        component: InviteUser
    },
    {
        path: "/user/:id",
        name: 'inviteUser',
        exact: true,
        pageTitle: "Invite User",
        component: InviteUser
    },
//    {
//        path: "/vendor/:orgId",
//        name: 'inviteUser',
//        exact: true,
//        pageTitle: "Invite User",
//        component: InviteUser
//    },

    {
        path: "/app-chat",
        name: 'chat',
        exact: true,
        pageTitle: "Chat",
        component: Chat
    },
    {
        path: "/app-inbox/:alertId/details",
        name: 'details',
        exact: true,
        pageTitle: "Details",
        component: Details
    },
    {
        path: "/app-compose",
        name: 'compose',
        exact: true,
        pageTitle: "Compose",
        component: Compose
    },
    {
        path: "/app-inbox",
        name: 'inbox',
        exact: true,
        pageTitle: "Inbox",
        component: Inbox
    },
    {
        path: "/app-contact2",
        name: 'gridView',
        exact: true,
        pageTitle: "Grid View",
        component: GridView
    },
    {
        path: "/app-contact",
        name: 'listView',
        exact: true,
        pageTitle: "List View",
        component: ListView
    },
    {
        path: "/form/form-wizard",
        name: 'formwizard',
        exact: true,
        pageTitle: "FormWizard",
        component: FormWizard
    },
    {
        path: "/form/form-validation",
        name: 'formvalidation',
        exact: true,
        pageTitle: "FormValidation",
        component: FormValidation
    },
    {
        path: "/form/form-summernote",
        name: 'formsummernote',
        exact: true,
        pageTitle: "FormSummernote",
        component: FormSummernote
    },
    {
        path: "/form/form-editors",
        name: 'formseditors',
        exact: true,
        pageTitle: "FormsEditors",
        component: FormsEditors
    },
    {
        path: "/form/form-dragdropupload",
        name: 'formdragdropupload',
        exact: true,
        pageTitle: "FormDragdropupload",
        component: FormDragdropupload
    },
    {
        path: "/form/form-cropping",
        name: 'formscropping',
        exact: true,
        pageTitle: "FormsCropping",
        component: FormCropping
    },
    {
        path: "/form/form-basic",
        name: 'formbasic',
        exact: true,
        pageTitle: "FormBasic",
        component: FormBasic
    },
    {
        path: "/form/form-advanced",
        name: 'formadvanced',
        exact: true,
        pageTitle: "FormAdvanced",
        component: FormAdvanced
    },
    {
        path: "/dashboard11",
        name: 'dashboard11',
        exact: true,
        pageTitle: "Dashboard11",
        component: Dashboard11
    },
    {
        path: "/dashboard10",
        name: 'dashboard10',
        exact: true,
        pageTitle: "Dashboard10",
        component: Dashboard10
    },
    {
        path: "/dashboard9",
        name: 'dashboard9',
        exact: true,
        pageTitle: "Dashboard9",
        component: Dashboard9
    },
    {
        path: "/dashboard8",
        name: 'dashboard8',
        exact: true,
        pageTitle: "Dashboard8",
        component: Dashboard8
    },
    {
        path: "/dashboard7",
        name: 'dashboard7',
        exact: true,
        pageTitle: "Dashboard7",
        component: Dashboard7
    },
    {
        path: "/dashboard6",
        name: 'dashboard6',
        exact: true,
        pageTitle: "Dashboard6",
        component: Dashboard6
    },
    {
        path: "/dashboard5",
        name: 'dashboard5',
        exact: true,
        pageTitle: "Dashboard5",
        component: Dashboard5
    },
    {
        path: "/dashboard4",
        name: 'dashboard4',
        exact: true,
        pageTitle: "Dashboard4",
        component: Dashboard4
    },
    {
        path: "/dashboard3",
        name: 'dashboard3',
        exact: true,
        pageTitle: "Dashboard3",
        component: Dashboard3
    },
    {
        path: "/dashboard2",
        name: 'dashboard2',
        exact: true,
        pageTitle: "Dashboard2",
        component: Dashboard2
    },
    {
        path: "/",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: Summary
    },
    {
        path: "/vendor/:orgid",
        name: 'dashboard',
        exact: true,
        pageTitle: "Dashboard",
        component: Summary
    },
    {
        path: "/login",
        name: 'login',
        exact: true,
        pageTitle: "Tables",
        component: Login
    },
    // {
    //     path: "/email-verfiy",
    //     name: 'email-verfiy',
    //     exact: true,
    //     pageTitle: "email-verfiy",
    //     component: EmailVerfiy
    // },
    // {
    //     path: "/profile-update",
    //     name: 'profileUpdate',
    //     exact: true,
    //     pageTitle: "ProfileUpdate",
    //     component: ProfileUpdate
    // },
    // {
    //     path: "/email-update",
    //     name: 'emailUpdate',
    //     exact: true,
    //     pageTitle: "EmailUpdate",
    //     component: EmailUpdate
    // },
    {
        path: "/signup",
        name: 'signup',
        exact: true,
        pageTitle: "Tables",
        component: Signup
    },
    {
        path: "/forgotpassword",
        name: 'forgotpassword',
        exact: true,
        pageTitle: "Tables",
        component: ForgotPassword
    },
    {
        path: "/maintenance",
        name: 'maintenance',
        exact: true,
        pageTitle: "Maintenance",
        component: Maintenance
    },
    {
        path: "/notfound",
        name: 'notfound',
        exact: true,
        pageTitle: "Tables",
        component: NotFound
    }
];

export default Routes;