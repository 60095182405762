import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchIpInventory,
  fetchIpInventoryTotal,
  fetchIpInventoryGraph,
  fetchIpInventoryAction,
} from "../../actions/inventory";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
// import BasicColumn from "../common/apexChart/basicColumn";
import Pagination from "../Pagination/Pagination";
import BasicColumn from "../common/apexChart/basicLineColumn";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import { is } from "immutable";

const IPBlacklist = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector((state) => {
    if (orgId) {
      return orgId;
    } else {
      return (
        state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
      );
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const dispatch = useDispatch();
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem("vendorName");
  const vendorId = sessionStorage.getItem("vendorId");

  let state = useSelector((state) => state?.inventory?.list);
  let totalRes = useSelector((state) => state?.inventory?.iptotals?.data);
  const scanCompleted = useSelector((state) => state?.inventory?.scanCompleted);

  const scanDetails = useSelector((state) => state?.inventory?.ipgraph?.data);

  const compliantPercent =
    (totalRes?.totalBlacklistedIPs || 0) / (totalRes?.totalIPs || 0);

  let uniqueCloudProvider = [];
  // let uniqueCloudProvider = state?.reduce((acc, curr) => {
  //   let str = JSON.stringify(curr.autonomous_system?.description);
  //   if (!str) return acc;
  //   str =
  //     str.indexOf("-") != -1
  //       ? str.split("-")[0]
  //       : str.indexOf(" ") != -1
  //         ? str.split(" ")[0]
  //         : str;
  //   str = str?.replace(/['"]+/g, "");
  //   if (curr?.blackListSource?.length > 0) {
  //     if (acc[str]) {
  //       acc[str].push(curr?.ip);
  //     } else {
  //       acc[str] = [curr?.ip];
  //     }
  //     // acc[str] = acc[str]
  //     return acc;
  //   } else {
  //     return acc;
  //   }
  // }, {});

  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails[details]);
  }

  useEffect(() => {
    if (orgId) {
      fetchIpInventory(orgId, dispatch, page, category, filterData);
    } else {
      fetchIpInventory(id, dispatch, page, category, filterData);
    }
  }, [id, dispatch, page, category, filterData]);

  useEffect(() => {
    fetchIpInventoryTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchIpInventoryGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(state?.totalIps);
  }, [state?.totalIps]);

  let mapData = state?.data;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const auth = useSelector((state) => state?.auth?.user);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        blackListedIPIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchIpInventoryAction(id, dispatch, page, category, filterData, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      blackListedIPIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchIpInventoryAction(id, dispatch, page, category, filterData, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      blackListedIPIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchIpInventoryAction(id, dispatch, page, category, filterData, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/black-listed-ips/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Ip Blacklisted.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Blacklisted IP Details</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? (
                    <>
                      <Link to="/vendor">Forest View</Link>
                    </>
                  ) : (
                    <Link to="/">Home</Link>
                  )}
                </li>
                {vendorName ? (
                  <li className="breadcrumb-item active" aria-current="page">
                    {vendorName}
                  </li>
                ) : (
                  ""
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  Details
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {totalRes?.individualRating}</b>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span>
              {client?.subscriptionType != "trial" && mapData?.length !== 0 && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                  }}
                  href="/add-report"
                  style={{ fontWeight: "bold" }}
                  className="btn btn-primary mr-1"
                >
                  <i className="fa fa-file-excel-o mr-1" style={{}}></i>{" "}
                  Download Details
                </a>
              )}
            </span>
          </div>
          {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
						<span className="btn btn-sm btn-primary mr-1" title="">Create Campaign</span>
						<Link to="https://themeforest.net/item/BrandSek-bootstrap-4x-admin-dashboard-clean-modern-ui-kit/23091507" className="btn btn-sm btn-success" title="Themeforest"><i className="icon-basket"></i> Buy Now</Link>
					</div> */}
        </div>
      </div>

      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "410px" }}>
              <h6>Compliance</h6>
              <div className="row text-center">
                <GaugeChart
                  id="gauge-chart3"
                  nrOfLevels={30}
                  colors={["#DD3731", "#b0f127"]}
                  arcWidth={0.3}
                  percent={(compliantPercent || 0)?.toFixed(2)}
                  textColor="#DD3731"
                />
              </div>

              <div className="form-group">
                <label className="d-block">
                  Blacklisted IPs
                  <span className="float-right">
                    {totalRes?.totalBlacklistedIPs || 0}/
                    {totalRes?.totalIPs || 0}
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-danger"
                    role="progressbar"
                    aria-valuenow={totalRes?.totalBlacklistedIPs?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: totalRes?.totalBlacklistedIPs?.toFixed(0) + "%",
                    }}
                  ></div>
                </div>
              </div>
              {Object?.keys(uniqueCloudProvider || {}).map((key) => {
                let value = uniqueCloudProvider[key];
                let percent =
                  ((value?.length || 0) / (state?.length || 0)) * 100;
                return (
                  <div className="form-group">
                    <label className="d-block">
                      IPs Blacklisted in {key} Servers{" "}
                      <span className="float-right">
                        {value?.length || 0}/{state?.length || 0}
                      </span>
                    </label>
                    <div className="progress progress-xxs">
                      <div
                        className="progress-bar progress-bar-warning"
                        role="progressbar"
                        aria-valuenow={percent?.toFixed(0)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: percent?.toFixed(0) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
              {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )}
              {/* <div className="form-group">
                <label className="d-block">
                  IPs Blacklisted in Google Servers{" "}
                  <span className="float-right">
                    {blackListedGoogleServer?.length || 0}/{state?.length || 0}
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-warning"
                    role="progressbar"
                    aria-valuenow={blackListedGoogleServerPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: blackListedGoogleServerPercent?.toFixed(0) + "%",
                    }}
                  ></div>
                </div>
              </div> */}
              {/* <div className="form-group">
                <label className="d-block">
                  IPs Blacklisted in Microsoft Servers{" "}
                  <span className="float-right">
                    {blacklistedMicrosoftServer?.length || 0}/
                    {state?.length || 0}
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={blacklistedMicrosoftServerPercent?.toFixed(
                      0
                    )}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width:
                        blacklistedMicrosoftServerPercent?.toFixed(0) + "%",
                    }}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card white-box-dashboard">
            <div className="body" style={{ minHeight: "410px" }}>
              <h6>Previous scan details</h6>
              {scanDetails && (
                <BasicColumn
                  dates={scanDetails?.dates}
                  ipArray={ipArray}
                  color={["#DD3731", "#b0f127"]}
                  yAxis="Total Host"
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12">
          <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-table-tab nav-link ${
                  category === "all" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("all");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                Current
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "risk-accepted" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("risk-accepted");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                Risk Accepted
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "false-positive" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("false-positive");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                False Positive
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link nav-table-tab ${
                  category === "remidiated" ? "active" : ""
                }`}
                onClick={() => {
                  setCategory("remidiated");
                  setPage(1);
                  setfilterData("all");
                }}
              >
                Remediated
              </a>
            </li>
          </ul>
          <div className="card">
            <div className="body">
              <div className="header">
                <div className="row">
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="blacklisted">Blacklisted</option>
                      <option value="not-blacklisted">Not Blacklisted</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    {selectedIds.length > 1 && category === "all" && (
                      <Box sx={{ width: 200 }}>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedValue}
                            onChange={(event) => {
                              setAction(event.target.value);
                              snooze("", event.target.value);
                            }}
                          >
                            <MenuItem value="pleaseSelect">
                              Snooze Options
                            </MenuItem>
                            <MenuItem
                              value="riskAccepted"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              Risk Accepted
                            </MenuItem>
                            <MenuItem
                              value="markAsFalsePositive"
                              onClick={() => {
                                addModal();
                              }}
                            >
                              False Positive
                            </MenuItem>
                            <MenuItem value="markAsRemidiated">
                              Remediated
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    {selectedIds.length > 1 &&
                      (category == "false-positive" ||
                        category == "risk-accepted") && (
                        <button
                          className="btn btn-primary btn-sm mr-1 Unsnooze"
                          onClick={() => Unsnoozed("")}
                        >
                          Unsnooze
                        </button>
                      )}
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      <tr>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={
                                selectedIds == 0 ? false : multiCheckTrue
                              }
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                        <th>Status</th>
                        <th>Host Name</th>
                        <th>Cloud / ISP Provider</th>
                        <th>Location</th>
                        <th>Blacklist Database</th>
                        <th>Last modified on</th>
                        {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                          category === "risk-accepted" ||
                          category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={5}>
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map((row, index) => {
                          return (
                            <tr>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <input
                                    onClick={() => singleClick(row?._id)}
                                    checked={selectedIds?.includes(row?._id)}
                                    type="checkbox"
                                    className="checkUncheck"
                                  />
                                </td>
                              )}
                              <td style={{ whiteSpace: "unset" }}>
                                {/* <span>{index + 1}</span> */}
                                <span
                                  className={
                                    row?.isBlackListed == true
                                      ? "badge badge-critical"
                                      : "badge badge-low"
                                  }
                                >
                                  {row?.isBlackListed == true
                                    ? "Blacklisted"
                                    : "Not blacklisted"}
                                </span>
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                <div className="d-flex align-items-center">
                                  <div
                                    className="ml-3"
                                    style={{
                                       
                                      fontWeight: 400,
                                      fontSize: 14,
                                    }}
                                  >
                                    {row?.ip}
                                  </div>
                                </div>
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.cloudOrISPProvider}
                              </td>

                              <td style={{ whiteSpace: "unset" }}>
                                {row?.location}
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.blackListSource?.map((row, index) => {
                                  return (
                                    <span
                                      id={`ipblacklist${index}`}
                                      data-tip={row}
                                      className="badge badge-warning"
                                    >
                                      {row}
                                    </span>
                                  );
                                })}
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                <span className="badge badge-success">
                                  {moment(
                                    row?.updatedAt || row?.createdAt
                                  ).format("DD-MM-YYYY")}
                                </span>
                              </td>
                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(row?._id, event.target.value);
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                              {/* <td>
                        {services?.map((res, index) => {
                          return (
                            <span className="badge badge-warning">{res}</span>
                          );
                        })}
                      </td> */}
                              {/* <td>
                        {row?.operating_system?.product?.toUpperCase() || "NA"}
                      </td> */}
                              {/* <td>
                        {row?.updatedAt
                          ? moment(row?.updatedAt).format("DD-MM-YYYY HH:MM")
                          : row?.createdAt
                          ? moment(row?.createdAt).format("DD-MM-YYYY HH:MM")
                          : "NA"}
                      </td> */}
                              {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                category === "false-positive") && (
                                <td>
                                  <button
                                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                                    onClick={() => Unsnoozed(row?._id)}
                                  >
                                    Unsnooze
                                  </button>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${
          isModal === "Form Wizard modal" ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
            <div className="">
            <div className="card mb-0">
                  <div className="row clearfix">
                    
                      <label className="col-lg-12 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                     
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                       
                    </div>
                  </div>
                   
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                          type="button"
                          onClick={() => {
                            setModal("");
                          setActionNote("");
                          }}
                          className="btn  btn-primary btn-brder-detail"
                          style={{ marginRight: "10px" }}
                        >
                         <i class="fa-solid fa-xmark mr-1"></i> Cancel
                        </button>
                       <button
                          type="button"
                          style={{ fontWeight: "bold" }}
                          onClick={submit}
                          className="btn  btn-primary"
                        >
                         <i class="fa-solid fa-floppy-disk mr-1"></i> Submit
                        </button>
                        
                        
                       
                    </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IPBlacklist;
